<template>
    <div>
        <div v-if="this.is_admin==1">
            <home3></home3>
        </div>
        <div v-if="this.is_admin==2">
            <home2></home2>
        </div>
    </div>
</template>
<script>
    import home3 from './home3/home3'
    import home2 from './home2/home2'

    export default {
        components: {home3, home2},
        name: "home",
        data() {
            return {
                is_admin: 0
            };
        },
        mounted() {
            this.Home()
            console.log(666, this.is_admin)
        },
        methods: {
            Home() {
                this.$sa0.post({
                    url: this.$api('Home'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.is_admin = 2
                        },
                        error: (response) => {
                            this.is_admin = 1
                            // layer.msg(response.message)
                        },
                    })
                })
            }
        },
    }
</script>
<style>
    .main_wrapper_top{top: 50px !important}
</style>
