const url_ = APIURL;
import l_api from './l'
import w_api from './w'
import z_api from './z'
import j1_api from './j1'
let url_array = {
    ...l_api,
    ...w_api,
    ...z_api,
    ...j1_api,
};
url_array['导航栏'] = `${url_}/admin/admin/get_nav_list.php`;
url_array['注销'] = `${url_}/admin/admin/logout.php`;
url_array['个人获取信息'] = `${url_}/admin/danda/danda_get_info.php`;
url_array['Login'] = `${url_}/admin/login.php`; //登录
url_array['AssetsUpload'] = `${url_}/admin/upload/upload_file.php`; //上传文件
url_array['Yo'] = `${url_}/admin/yo.php`;
const api = (mark) => {
    if (mark === '') return url_;
    return url_array[mark];
}
export default api;
