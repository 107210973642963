<template>
    <div class="app_wrapper">
        <a-config-provider :locale="locale">
            <div id="app">
                <a-spin :spinning="$store.state.loading > 0">
                    <router-view/>
                </a-spin>
            </div>
        </a-config-provider>
    </div>
</template>

<script>
    import zhCn from 'ant-design-vue/lib/locale-provider/zh_CN'

    export default {
        data() {
            return {
                locale: zhCn
            }
        },
        name: "App"
    }
</script>

<style>
    .ant-popover{ z-index: 9999999999 !important}

    #app{
        position: relative;
        width: 100%;
        height: 100vh;
        }

    .app_wrapper{
        position: relative;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        }

    body{
        font-family: 'SFMono-Regular' !important; font-size: 12px !important;
        }

    .layui-table, .layui-table td, .layui-table th{
        border: 1px solid #eeeeee !important;
        }

    .layui-layer-content{
        padding: 20px
        }

    .main_wrapper{
        bottom: 10px !important;
        overflow-y: hidden;
        }

    .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot{
        position: fixed !important;
        top: 50% !important;
        left: 50%;
        margin: -10px;
        }

    .ant-select-selection--single{
        position: relative;
        height: 28px !important;
        cursor: pointer;
        }

    .ant-select-selection__rendered{
        position: relative;
        display: block;
        margin-right: 11px;
        margin-left: 11px;
        line-height: 28px !important;
        font-size: 12px;
        }

    .ant-input{ height: 28px !important; line-height: 28px !important; font-size: 12px !important}

    .layui-table th{
        text-align: left; font-weight: bold;
        }

    .ant-form label{
        font-size: 12px !important;
        }

    .ant-btn-sm{ font-size: 12px !important; }

    .tree_item_wrapper{ line-height: 30px !important}

    .jiantou{ font-size: 10px !important}

    .ant-btn{
        line-height: 25px !important;
        height: 28px !important;
        font-size: 12px !important;
        }

    .clearbtn{ background: #fcfcfc !important}

    .yellow{ color: #fe8203 !important}

    .ant-modal-confirm-body .ant-modal-confirm-title{
        font-size: 14px !important;
        }

    .menu_wrapper{
        font-size: 12px !important;
        }

    .ant-breadcrumb{ font-size: 12px !important}

    .ant-drawer-title{ font-size: 14px !important}

    .ant-tabs-nav-container{ font-size: 12px !important}

    .redcolor{ color: #ff0000 !important}

    .ant-form-item, .ant-checkbox-wrapper{ font-size: 12px !important}

    .layui-table th{
        background: #f9f9f9 !important; text-align: center !important;
        }

    .layui-table tr td{ text-align: center}

    .wraper{ height: auto !important}

    textarea.ant-input{
        height: 80px !important;
        }

    .ltree_wrapper::-webkit-scrollbar{
        /*滚动条整体样式*/
        width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 0px;
        }

    .ltree_wrapper::-webkit-scrollbar-thumb{
        /*滚动条里面小方块*/
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #535353;
        }

    .ltree_wrapper::-webkit-scrollbar-track{
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background: #ededed;
        }


    .tree_wrapper::-webkit-scrollbar{
        /*滚动条整体样式*/
        width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 0px;
        }

    .tree_wrapper::-webkit-scrollbar-thumb{
        /*滚动条里面小方块*/
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #535353;
        }

    .tree_wrapper::-webkit-scrollbar-track{
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background: #ededed;
        }

    .layui-table img{
        max-width: auto !important;
        }

    .btner{ background: rgb(235, 248, 254) !important;}

    .lmenu_wrapper{ z-index: 9999999999 !important}
</style>
