const url_ = APIURL;
let url_array = {};



url_array['Del_moban'] = `${url_}/admin/moban/del_moban.php`; // w删除消息模板

url_array['Del_report_tpl.php'] = `${url_}/admin/report/del_report_tpl.php`; // w删除报告模板
url_array['Del_original_record_tpl'] = `${url_}/admin/originalRecord/del_original_record_tpl.php`; // w删除原始记录单模板

url_array['Weituodan_list_'] = `${url_}/admin/weituodan/weituodan_list_.php`; // w委托单列表

url_array['Moban_list_group'] = `${url_}/admin/moban/moban_list_group.php`; // w检测日志

url_array['Get_source_record_log'] = `${url_}/admin/result/get_source_record_log.php`; // w检测日志
url_array['Set_source_record_all'] = `${url_}/admin/result/set_source_record_all.php`; // w设置状态
url_array['Get_spec_item'] = `${url_}/admin/result/get_spec_item_.php`; // w列表
url_array['Get_task'] = `${url_}/admin/result/get_task.php`; // w委托单加样品梳妆台

url_array['Edit_moban'] = `${url_}/admin/moban/edit_moban.php`; // w修改模板
url_array['Set_moban'] = `${url_}/admin/moban/set_moban.php`; // w设置当前模板
url_array['Create_moban'] = `${url_}/admin/moban/create_moban.php`; // w获取分类模板列表
url_array['Get_moban_list'] = `${url_}/admin/moban/get_moban_list.php`; // w获取分类模板列表
url_array['Get_notice_type'] = `${url_}/admin/moban/get_notice_type.php`; // w通知分类
url_array['Get_tag_info'] = `${url_}/admin/moban/get_tag_info.php`; // w通配符列表
url_array['Moban_list'] = `${url_}/admin/moban/moban_list.php`; // w模板列表
url_array['Set_notice_type'] = `${url_}/admin/moban/set_notice_type.php`; // y设置是否启用

url_array['before_start_check'] = `${url_}/admin/task_check/before_start_check.php`; // w删除检测项目
url_array['Del_jc_item'] = `${url_}/admin/ljy/del_jc_item.php`; // w删除检测项目
url_array['Add_sample_item_jcitemid'] = `${url_}/admin/ljy/add_sample_item_jcitemid.php`; // w修改
url_array['Del_sample_item'] = `${url_}/admin/ljy/del_sample_item.php`; // w选中移除

url_array['Edit_sample_item'] = `${url_}/admin/ljy/edit_sample_item.php`; // w修改样品类型
url_array['Get_sample_item'] = `${url_}/admin/ljy/get_sample_item.php`; // w列表
url_array['Create_sample_item'] = `${url_}/admin/ljy/create_sample_item.php`; // w创建样品类型
url_array['Edit_sample_item'] = `${url_}/admin/ljy/edit_sample_item.php`; // w修改样品类型
url_array['Get_samplecategory_detail'] = `${url_}/admin/ljy/get_samplecategory_detail.php`; // w根据id获取详情

url_array['Get_jc_item_list'] = `${url_}/admin/ljy/get_jc_item_list.php`; // y检验项目 检测方法  检测标准细则 去重自动完成
url_array['Edit_jc_item'] = `${url_}/admin/ljy/edit_jc_item.php`; //y修改基础信息
url_array['Create_jc_item_pz'] = `${url_}/admin/ljy/create_jc_item_pz.php`; //y修改参数配置
url_array['Get_jc_pz_ex_environ'] = `${url_}/admin/yjs/get_jc_pz_ex_environ.php`; //y检测项目 参数配置 已选实验条件

url_array['Jc_item_list'] = `${url_}/admin/ljy/jc_item_list.php`; // w检测项目列表
url_array['Executive_bz_list'] = `${url_}/admin/ljy/executive_bz_list.php`; // w执行标准列表

url_array['Del_apparatus_condition'] = `${url_}/admin/ljy/del_apparatus_condition.php`; // w删除仪器条件
url_array['Set_apparatus_condition'] = `${url_}/admin/ljy/set_apparatus_condition.php`; // w设置仪器条件
url_array['Del_jc_item_apparatus'] = `${url_}/admin/ljy/del_jc_item_apparatus.php`; // w移除仪器
url_array['create_jc_item_apparatus'] = `${url_}/admin/ljy/create_jc_item_apparatus.php`; // w设置仪器
url_array['Apparatus_list'] = `${url_}/admin/ljy/apparatus_list.php`; // w仪器列表
url_array['Method_category_alllist'] = `${url_}/admin/ljy/method_category_alllist.php`; // w方法类别列表
url_array['Check_bz_list'] = `${url_}/admin/ljy/check_bz_list.php`; // w检测标准列表(不分页)
url_array['Create_jc_item'] = `${url_}/admin/ljy/create_jc_item.php`; // w创建基础信息
url_array['Get_jc_item'] = `${url_}/admin/ljy/get_jc_item.php`; // w列表
url_array['Get_check_bz_groupby'] = `${url_}/admin/ljy/get_check_bz_groupby.php`; // w细则列表
url_array['Create_check_bz_serial_all'] = `${url_}/admin/ljy/create_check_bz_serial_all.php`; // w批量保存细则编号
url_array['Get_check_bz_log'] = `${url_}/admin/ljy/get_check_bz_log.php`; // w检测标准历史记录
url_array['Del_check_bz_serial'] = `${url_}/admin/ljy/del_check_bz_serial.php`; // w删除细则编号
url_array['Edit_check_bz_serial'] = `${url_}/admin/ljy/edit_check_bz_serial.php`; // w修改检测标准细则
url_array['Create_check_bz_serial'] = `${url_}/admin/ljy/create_check_bz_serial.php`; // w创建检测标准细则
url_array['Get_check_bz_serial'] = `${url_}/admin/ljy/get_check_bz_serial.php`; // w细则编号列表
url_array['Del_check_bz'] = `${url_}/admin/ljy/del_check_bz.php`; // w删除检测标准文档
url_array['Edit_check_bz'] = `${url_}/admin/ljy/edit_check_bz.php`; // w修改检测标准
url_array['Create_check_bz'] = `${url_}/admin/ljy/create_check_bz.php`; // w创建检测标准
url_array['Get_check_bz'] = `${url_}/admin/ljy/get_check_bz.php`; // w检测标准文档列表(2022-2-22)

url_array['Delete_report'] = `${url_}/admin/report/delete_report.php`; // w删除检测报告
url_array['Create_report'] = `${url_}/admin/report/create_report.php`; // w创建检测报告
url_array['Report_tpl_list'] = `${url_}/admin/report/report_tpl_list.php`; // w检测报告模板列表(2022-1-21)

url_array['Set_menu'] = `${url_}/admin/danda/set_menu.php`; // w设置导航
url_array['Get_admin_menu'] = `${url_}/admin/danda/get_admin_menu.php`; // w获取导航列表(2022-1-13)

url_array['Del_check_table'] = `${url_}/admin/to_check/del_check_table.php`; // w删除检测原始记录单
url_array['Create_check_table'] = `${url_}/admin/to_check/create_check_table.php`; // w创建检测原始记录单
url_array['Get_check_tpl'] = `${url_}/admin/to_check/get_check_tpl.php`; // w获取模板列表(2022-1-10)

url_array['Danda_acid_list'] = `${url_}/admin/ljy/danda_acid_list.php`; // w获取审核人员(2021-12-18)
url_array['Set_specitem_lingqu'] = `${url_}/admin/spec_item/set_specitem_lingqu.php`; // w每个人领取任务(2021-12-16)
url_array['GGet_list'] = `${url_}/admin/spec_item/get_list.php`; // w接受采集列表(2021-12-15)
url_array['Shijijingshi_export'] = `${url_}/admin/ljy/shijijingshi_export.php`; // w试剂警示导出
url_array['Shijizubieguanli_export'] = `${url_}/admin/ljy/shijizubieguanli_export.php`; // w试剂组别导出
url_array['Manufacturer_export'] = `${url_}/admin/ljy/manufacturer_export.php`; // w生成厂家导出
url_array['Shijichuruku_export'] = `${url_}/admin/ljy/shijichuruku_export.php`; // w试剂出入库导出记录
url_array['Shijikucun_export'] = `${url_}/admin/ljy/shijikucun_export.php`; // w试剂库存管理导出

url_array['Tuihui_specitem'] = `${url_}/admin/task_check/tuihui_specitem.php`; // w申请样品
url_array['Shenqing_specitem'] = `${url_}/admin/task_check/shenqing_specitem.php`; // w申请样品
url_array['Lingqu_taskcheck'] = `${url_}/admin/task_check/lingqu_taskcheck.php`; // w领取任务
url_array['Get_list'] = `${url_}/admin/task_check/get_list.php`; // w检测人列表(2021-12-9)

url_array['Set_tuihuireceive'] = `${url_}/admin/spec_item/set_tuihuireceive.php`; // w接收人确认退回检测人申请
url_array['Get_tuihuitem'] = `${url_}/admin/spec_item/get_tuihuitem.php`; // w接收人退回弹窗信息(2021-12-7)
url_array['Get_tuihuireceive'] = `${url_}/admin/spec_item/get_tuihuireceive.php`; // w检测人退回信息
url_array['Get_specreceive'] = `${url_}/admin/spec_item/get_specreceive.php`; // w确认/拒绝样品领取
url_array['Set_lingquitem'] = `${url_}/admin/spec_item/set_lingquitem.php`; // w确认/拒绝样品领取
url_array['Set_liuyangitem'] = `${url_}/admin/spec_item/set_liuyangitem.php`; // w样品拒收
url_array['Set_jushouitem'] = `${url_}/admin/spec_item/set_jushouitem.php`; // w样品拒收
url_array['Get_jushoutuihuilist'] = `${url_}/admin/spec_item/get_jushoutuihuilist.php`; // w采集人拒收退回记录
url_array['Set_tuihuiitem'] = `${url_}/admin/spec_item/set_tuihuiitem.php`; // w样品退回
url_array['Get_specreceive'] = `${url_}/admin/spec_item/get_specreceive.php`; // w样品申请弹出
url_array['Set_jieshouitem'] = `${url_}/admin/spec_item/set_jieshouitem.php`; // w样品接受完成
url_array['Jieshou_itemrecord'] = `${url_}/admin/spec_item/jieshou_itemrecord.php`; // w接受时间轴
url_array['Get_jieshoulist'] = `${url_}/admin/spec_item/get_jieshoulist.php`; // w接收人列表
url_array['Set_spec_item'] = `${url_}/admin/spec_item/set_spec_item.php`; // w设置采集状态
url_array['Samplecategoryalllist'] = `${url_}/admin/ljy/samplecategoryalllist.php`; // w获取样品类型所有分级列表
url_array['Edit_caijiitem'] = `${url_}/admin/spec_item/edit_caijiitem.php`; // w时间轴列表
url_array['Caiji_itemrecord'] = `${url_}/admin/spec_item/caiji_itemrecord.php`; // w时间轴列表
url_array['Samplecategory'] = `${url_}/admin/ljy/samplecategory.php`; // w样品类型文件夹列表
url_array['Get_caijilist'] = `${url_}/admin/spec_item/get_caijilist.php`; // w采集人列表

url_array['Task_list_page'] = `${url_}/admin/task/task_list_page.php`; // w委托单分页列表

url_array['Del_factor'] = `${url_}/admin/ljy/del_factor.php`; // w删除因子
url_array['Edit_factor'] = `${url_}/admin/ljy/edit_factor.php`; // w修改因子
url_array['Create_factor'] = `${url_}/admin/ljy/create_factor.php`; // w创建因子
url_array['Getfactorlist'] = `${url_}/admin/ljy/getfactorlist.php`; // w因子列表

url_array['Get_taglist'] = `${url_}/admin/ljy/get_taglist.php`; // w根据Id获取标签
url_array['Get_default_tag'] = `${url_}/admin/ljy/get_default_tag.php`; // w获取默认标签
url_array['Set_tag'] = `${url_}/admin/ljy/set_tag.php`; // w设置默认
url_array['Del_tag'] = `${url_}/admin/ljy/del_tag.php`; // w删除标签
url_array['Edit_tag'] = `${url_}/admin/ljy/edit_tag.php`; // w修改标签
url_array['Create_tag'] = `${url_}/admin/ljy/create_tag.php`; // w创建标签
url_array['Taglist'] = `${url_}/admin/ljy/taglist.php`; // w标签列表(2021-11-29)
url_array['Get_tag'] = `${url_}/admin/ljy/get_tag.php`; // w标签列表
url_array['qrCode'] = `${url_}/qrcode`; // w生成条形码二维码

url_array['set_announcementBI_show'] = `${url_}/admin/ljy/set_announcementBI_show.php`; // w设置公告BI是否显示
url_array['set_announcementhome_show'] = `${url_}/admin/ljy/set_announcementhome_show.php`; // w设置公告首页是否显示
url_array['Set_announcement'] = `${url_}/admin/ljy/set_announcement.php`; // w撤回公告
url_array['Edit_announcement'] = `${url_}/admin/ljy/edit_announcement.php`; // w修改公告
url_array['Create_announcement'] = `${url_}/admin/ljy/create_announcement.php`; // w发布公告
url_array['Get_announcement'] = `${url_}/admin/ljy/get_announcement.php`; // w获取公告分页列表

url_array['Rulenumlist'] = `${url_}/admin/ljy/rulenumlist.php`; // w编号分页列表(2021-11-29)
url_array['Set_rulenum'] = `${url_}/admin/ljy/set_rulenum.php`; // w修改默认编号
url_array['Get_serialnumber'] = `${url_}/admin/ljy/get_serialnumber.php`; // w选择请求编号接口
//url_array['Get_serialnumber'] = `${url_}/admin/ljy/get_default_serialnumber.php`; // w请求编号接口 （选择默认编号暂时没用）
url_array['Del_rulenum'] = `${url_}/admin/ljy/del_rulenum.php`; // w删除
url_array['Edit_rulenum'] = `${url_}/admin/ljy/edit_rulenum.php`; // w修改规则编号
url_array['Create_rulenum'] = `${url_}/admin/ljy/create_rulenum.php`; // w创建编号规则
url_array['Get_rulenum'] = `${url_}/admin/ljy/get_rulenum.php`; // w编号列表

url_array['Reagent_infostock_warn'] = `${url_}/admin/ljy/reagent_infostock_warn.php`; // w报警列表
url_array['Edit_reagentstock'] = `${url_}/admin/ljy/edit_reagentstock.php`; // w修改库存数量和价格
url_array['Py'] = `${url_}/admin/ljy/py.php`; // w获取拼音首字母
url_array['Get_reagentstock'] = `${url_}/admin/ljy/get_reagentstock.php`; // w入库/出库/报损记录
url_array['Create_reagentstock'] = `${url_}/admin/ljy/create_reagentstock.php`; // w入库/出库/报损操作
url_array['Reagent_infostock'] = `${url_}/admin/ljy/reagent_infostock.php`; // w试剂管理列表

url_array['Del_reagentinfo'] = `${url_}/admin/ljy/del_reagentinfo.php`; // w删除试剂信息
url_array['Edit_reagentinfo'] = `${url_}/admin/ljy/edit_reagentinfo.php`; // w修改试剂信息
url_array['Create_reagentinfo'] = `${url_}/admin/ljy/create_reagentinfo.php`; // w创建试剂信息
url_array['Get_reagentinfo'] = `${url_}/admin/ljy/get_reagentinfo.php`; // w获取试剂信息分页列表

url_array['Dandaalllist'] = `${url_}/admin/ljy/dandaalllist.php`; // w获取部门人员分级列表
url_array['Dandalist'] = `${url_}/admin/ljy/dandalist.php`; // w获取部门人员分级列表
url_array['Sampletypelist'] = `${url_}/admin/ljy/sampletypelist.php`; // w获取基础字段全部列表--获取试剂组别列表

url_array['Del_manufacturer'] = `${url_}/admin/ljy/del_manufacturer.php`; // w删除生产厂家
url_array['Edit_manufacturer'] = `${url_}/admin/ljy/edit_manufacturer.php`; // w修改生产厂家
url_array['Create_manufacturer'] = `${url_}/admin/ljy/create_manufacturer.php`; // w创建生产厂家
url_array['Get_manufacturer'] = `${url_}/admin/ljy/get_manufacturer.php`; // w获取生产厂家列表
url_array['Manufacturerlist'] = `${url_}/admin/ljy/manufacturerlist.php`; // w获取生产厂家列表(2021-11-29)


url_array['Del_laboratorymonitor'] = `${url_}/admin/ljy/del_laboratorymonitor.php`; // w删除实验室监控记录
url_array['Create_laboratorymonitor'] = `${url_}/admin/ljy/create_laboratorymonitor.php`; // w创建实验室监控
url_array['Edit_laboratorymonitor'] = `${url_}/admin/ljy/edit_laboratorymonitor.php`; // w修改实验室监控
url_array['Get_laboratorymonitor'] = `${url_}/admin/ljy/get_laboratorymonitor.php`; // w获取实验室监控记录
url_array['Set_laboratory'] = `${url_}/admin/ljy/set_laboratory.php`; // w开启关闭实验室
url_array['Del_laboratory'] = `${url_}/admin/ljy/del_laboratory.php`; // w删除实验室
url_array['Edit_laboratory'] = `${url_}/admin/ljy/edit_laboratory.php`; // w修改实验室
url_array['Create_laboratory'] = `${url_}/admin/ljy/create_laboratory.php`; // w创建实验室
url_array['Get_laboratory'] = `${url_}/admin/ljy/get_laboratory.php`; // w获取实验室列表


url_array['Create_detmethod'] = `${url_}/admin/ljy/create_detmethod.php`; // w创建检测方法
url_array['Edit_detmethod'] = `${url_}/admin/ljy/edit_detmethod.php`; // w修改检测方法
url_array['Del_detmethod'] = `${url_}/admin/ljy/del_detmethod.php`; // w删除检测方法
url_array['Methodlist'] = `${url_}/admin/ljy/methodlist.php`; // w检测方法所有列表
url_array['Methodalllist'] = `${url_}/admin/ljy/methodalllist.php`; // w检测方法分级列表
url_array['Get_detmethod_detail'] = `${url_}/admin/ljy/get_detmethod_detail.php`; // w根据Id获取检测方法详情

url_array['Get_documentlog'] = `${url_}/admin/ljy/get_documentlog.php`; // w查看历史记录
url_array['upload_file'] = `${url_}/admin/ljy/upload_file.php`; //w文件上传
url_array['Del_document'] = `${url_}/admin/ljy/del_document.php`; //w删除文档
url_array['Edit_document'] = `${url_}/admin/ljy/edit_document.php`; //w修改标准文档
url_array['Create_document'] = `${url_}/admin/ljy/create_document.php`; //w创建检测标准文档
url_array['Get_document'] = `${url_}/admin/ljy/get_document.php`; //w获取列表带分页
url_array['Documentlist'] = `${url_}/admin/ljy/documentlist.php`; //w获取文档所有列表用于select

url_array['Detitemalllist'] = `${url_}/admin/ljy/detitemalllist.php`; //w获取检测项目所有分级列表
url_array['Get_detitem_detail'] = `${url_}/admin/ljy/get_detitem_detail.php`; //w根据id获取检测项目详情
url_array['Del_detitem'] = `${url_}/admin/ljy/del_detitem.php`; //w删除检测项目
url_array['Edit_detitem'] = `${url_}/admin/ljy/edit_detitem.php`; //w修改检测项目
url_array['Create_detitem'] = `${url_}/admin/ljy/create_detitem.php`; //w创建检测项目
url_array['Detitemlist'] = `${url_}/admin/ljy/detitemlist.php`; //w获取检测项目分级列表


url_array['Edit_sampletype'] = `${url_}/admin/ljy/edit_sampletype.php`; // w修改基础字段
url_array['Del_sampletype'] = `${url_}/admin/ljy/del_sampletype.php`; // w删除基础字段
url_array['Sampletypelist'] = `${url_}/admin/ljy/sampletypelist.php`; // w获取基础字段全部列表
url_array['Get_sampletype'] = `${url_}/admin/ljy/get_sampletype.php`; // w获取基础字段列表 分页
url_array['Create_sampletype'] = `${url_}/admin/ljy/create_sampletype.php`; // w创建基础字段
url_array['Apparatus_list'] = `${url_}/admin/ljy/apparatus_list.php`; // w核酸检测设备列表
url_array['Auditor_list'] = `${url_}/admin/yjs/auditor_list.php`; // a当前审核人员列表
url_array['Cancle_nucleic'] = `${url_}/admin/yjs/cancle_nucleic.php`; // c取消审核



url_array['Check_bz_name'] = `${url_}/admin/ljy/check_bz_name.php`; // y检验项目 检测方法  检测标准细则 去重自动完成
url_array['Get_jc_item_xz_serial'] = `${url_}/admin/ljy/get_jc_item_xz_serial.php`; // y检验项目 检测方法  检测标准细则 去重自动完成
url_array['Get_jc_item_item_name'] = `${url_}/admin/ljy/get_jc_item_item_name.php`; // y检验项目 检测方法  检测标准细则 去重自动完成
url_array['Get_jc_item_method_name'] = `${url_}/admin/ljy/get_jc_item_method_name.php`; // y检验项目 检测方法  检测标准细则 去重自动完成
url_array['Get_jc_item_list'] = `${url_}/admin/ljy/get_jc_item_list.php`; // y检验项目 检测方法  检测标准细则 去重自动完成
url_array['Edit_jc_item'] = `${url_}/admin/ljy/edit_jc_item.php`; //y修改基础信息
url_array['Create_jc_item_pz'] = `${url_}/admin/ljy/create_jc_item_pz.php`; //y修改参数配置
export default url_array;
