import axios from 'axios'
import config from '../../config.js'

const get = axios.create({
    timeout: config.postTimeout
})

get.interceptors.request.use(
    config => {
        config.method = 'get'
        config.params = config.data
        return config
    },
    error => Promise.reject(error)
)
get.interceptors.response.use(
    response =>
        (response.status === 200)
            ? response
            : Promise.reject('[ERROR] response.status: ' + response.status),
    error => Promise.reject(error)
)
export default get;
