<script>
import Nav from './nav.vue'
import store from "../../store/store";

export default {
  components: {Nav},
  methods: {
    quit() {
      this.$sa0.post({
        url: this.$api('注销'),
        data: {
          token_id: this.$store.state.admin_info.token.id
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.$member.delToken()
            this.$store.state.tab = []
            this.$sa0.localStorage.del('USER_account')
            setTimeout(() => {
              this.menuItemClick('/login')
            })
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    edit_key(){
      this.$router.push('/eidpassword/index')
    },
    menuItemClick(path) {
      this.$router.push(path)
    }
  }
}
</script>
<template>
  <div class="header_wrapper">
    <div class="logo_wrapper">{{ $config.title }}</div>
    <div class="nav_wrapper">
      <Nav></Nav>
    </div>
    <div class="user_wrapper">
      <a-dropdown placement="bottomCenter">
        <div class="user_info_wrapper">
          <img src="/assets/avatar.jpeg" alt="">
          <span class="user_name_wrapper">{{ $store.state.admin_info.info.name }}</span>
        </div>
        <a-menu slot="overlay">
          <a-menu-item @click="menuItemClick('/')">
            <span>后台首页</span>
          </a-menu-item>
          <a-menu-item @click="edit_key()">
            <span>修改密码</span>
          </a-menu-item>
          <a-menu-item @click="quit()">
            <span>退出登录</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>
<style>
.ant-menu {
  background: #252b3a !important;
}
</style>
<style scoped>


.user_info_wrapper {
  position: relative;
  height: 60px;
  width: 100%;
}

.user_name_wrapper {
  position: absolute;
  left: 60px;
  line-height: 60px;
}

.user_info_wrapper img {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  position: absolute;
  top: 10px;
}

.user_wrapper {
  padding-left: 20px;
  background: #384053;
  position: absolute;
  right: 100px;
  width: 200px;
  top: 0;
  bottom: 0;
  right: 0;
}

.nav_wrapper {
  position: absolute;
  top: 0;
  left: 200px;
  right: 300px;
  bottom: 0;
}

.logo_wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  width: 200px;
  line-height: 60px;
  text-align: center;
  font-size: 14px;
}

.header_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #252b3a;
  color: #ffffff;
}
</style>
