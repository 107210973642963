<script>
import Header from './header.vue'
import Tab from './tab.vue'
import Breadcrumb from './breadcrumb.vue'

export default {
  components: {
    Header, Tab, Breadcrumb
  }
}
</script>
<template>
  <div>
    <div class="header_wrapper">
      <Header></Header>
    </div>
    <div class="tab_wrapper" v-if="false">
      <Tab ></Tab>
    </div>
    <div v-if="$route.name != 'home'" class="breadcrumb_wrapper">
      <Breadcrumb></Breadcrumb>
    </div>
    <div class="main_wrapper" :class="[$route.name != 'home'?'':'main_wrapper_top']">
      <router-view/>
    </div>
  </div>
</template>
<style scoped>


.main_wrapper {
  margin-top: 10px;
  padding: 0 20px;
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 20px;
  overflow-y: auto;
}

.main_wrapper_top {
  top: 100px;
}

.breadcrumb_wrapper {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  height: 50px;
  background: #f0f2f5;
}

.tab_wrapper {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  height: 50px;
  background: #f0f2f5;
}

.header_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 2;
  box-shadow: 0 0 10px 0 #00000050;
}
</style>
