import axios from 'axios'
import config from '../../config.js'
import member from '../../tool/member'
import store from "../../store/store";

const file = axios.create({
    timeout: config.postTimeout
})

file.interceptors.request.use(
    config => {
        store.state.loading = store.state.loading + 1
        config.headers['Authorization'] = 'Bearer ' + (member.getToken() ? member.getToken() : '')
        config.headers['Content-Type'] = 'multipart/form-data'
        config.method = 'post'
        return config
    },
    error => Promise.reject(error)
)
file.interceptors.response.use(
    response => {
        store.state.loading = store.state.loading - 1
        return (response.status === 200) ? response : Promise.reject('[ERROR] response.status: ' + response.status)
    },
    error => {
        store.state.loading = store.state.loading - 1
        Promise.reject(error)
    }
)
export default file;
