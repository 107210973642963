import router from './router/router'
import store from "./store/store";
import storage from './sa0/lib/localStorage'
import config from "./config";
import member from './tool/member'
import api from './api/api'
import sa0 from './sa0/sa0'

const noTab = ['login', '404', 'daping', 'dapingstyle1', 'noauth', 'devPostman', 'AutoInput', 'mathjax']

const jumpDo = ['login', '404', 'noauth', 'dapingstyle1', 'devPostman', 'AutoInput', 'mathjax']
router.beforeEach(async (to, from, next) => {
    console.log(to)
    if (jumpDo.indexOf(to.name) !== -1) {
        sa0.post({
            url: api('个人获取信息'),
        }).then((response) => {
            sa0.response({
                response: response.data,
                then: (response) => {
                    store.state.admin_info = response.data
                    next()
                },
                error: () => {
                    if (noTab.indexOf(to.name) === -1) {
                        member.delToken()
                        layer.msg('需要重新登陆')
                        next('/login')
                    } else {
                        next()
                    }
                },
            })
        })
    } else {
        let t = to.name
        if (to.name === 'home') t = '登录'
        sa0.post({
            url: api('获取是否有权限'),
            data: {
                "title": t
            }
        }).then((response) => {
            sa0.response({
                response: response.data,
                then: (response) => {
                    let token = member.getToken();
                    let user_account = storage.get('USER_account');
                    user_account = user_account ? user_account : '';
                    const tabListKey = 'TAB_LIST' + user_account
                    store.state.nav_current = [typeof to.meta.active === 'undefined' ? to.name : to.meta.active]
                    if (to.name !== 'home') {
                        document.title = `${to.meta.title} | ${config.title}`
                    } else {
                        document.title = config.title
                    }
                    if (noTab.indexOf(to.name) === -1) {
                        let breadcrumb = [{title: '首页', key: 'home', path: '/'}]
                        if (to.name !== 'home') {
                            if (to.matched[0].name !== to.matched[1].name) {
                                breadcrumb.push({
                                    title: to.matched[0].meta.title,
                                    key: to.matched[0].name,
                                    path: typeof to.matched[0].meta.path === 'undefined' ? to.matched[0].path : to.matched[0].meta.path
                                })
                            }
                            breadcrumb.push({
                                title: to.matched[1].meta.title,
                                key: to.matched[1].name,
                                path: typeof to.matched[1].meta.path === 'undefined' ? to.matched[1].path : to.matched[1].meta.path
                            })
                        }
                        store.state.breadcrumb = breadcrumb
                        let tab = store.state.tab
                        if (tab.length === 0) {
                            tab = storage.get(tabListKey)
                            if (!tab) tab = [{title: '首页', key: 'home', path: '/', closable: false}]
                        }
                        let has = -1
                        for (let i in tab) {
                            if (to.name === tab[i].key) {
                                has = i
                                break
                            }
                        }
                        if (has === -1) {
                            tab.push({
                                title: to.meta.title,
                                key: to.name,
                                path: to.fullPath
                            })
                        } else {
                            tab[has] = {
                                title: to.meta.title,
                                key: to.name,
                                path: to.fullPath
                            }
                        }
                        storage.set(tabListKey, tab)
                        store.state.tab = tab
                        store.state.tab_active = to.name
                    }

                    if (token || token !== '') {
                        sa0.post({
                            url: api('个人获取信息'),
                        }).then((response) => {
                            sa0.response({
                                response: response.data,
                                then: (response) => {
                                    store.state.admin_info = response.data
                                    next()
                                },
                                error: () => {
                                    if (noTab.indexOf(to.name) === -1) {
                                        member.delToken()
                                        storage.del('USER_account')
                                        store.state.tab = []
                                        layer.msg('需要重新登陆')
                                        next('/login')
                                    } else {
                                        next()
                                    }
                                },
                            })
                        })
                    } else {
                        if (noTab.indexOf(to.name) === -1) {
                            next('/login')
                        } else {
                            next()
                        }
                    }
                },
                error: (response) => {
                    if (Number(response.code) === 201) {
                        next('/404')
                    } else {
                        next('/login')
                    }
                },

            })
        })
    }


})

router.afterEach(() => {
})
