const url_ = APIURL;
let url_array = {};
url_array['二级：保存人员列表'] = `${url_}/admin/admin/saveerjiren.php`
url_array['二级：人员列表'] = `${url_}/admin/admin/geterjirenlist.php`
url_array['二级：领取采集任务'] = `${url_}/admin/task_list/get.php`
url_array['二级：确认委托任务'] = `${url_}/admin/erjiweituo/done.php`
url_array['二级：委托任务列表2'] = `${url_}/admin/erjiweituo/list2.php`
url_array['二级：委托任务列表'] = `${url_}/admin/erjiweituo/list.php`
url_array['二级：创建委托任务'] = `${url_}/admin/erjiweituo/create.php`
url_array['二级：保存委托任务'] = `${url_}/admin/erjiweituo/callback.php`
url_array['二级：采集任务列表'] = `${url_}/admin/task_list/list.php`
url_array['二级：创建采集任务'] = `${url_}/admin/task_list/create.php`

url_array['二级：搜索下级样品委托单'] = `${url_}/admin/erjiyangpin/get.php`
url_array['二级：保存下级样品委托单'] = `${url_}/admin/erjiyangpin/save.php`

url_array['检测项目：检测项目仪器列表'] = `${url_}/admin/ljy/get_jc_item_apparatus.php`
url_array['公式：检测结果判定'] = `${url_}/admin/jc_item/jc_item_result.php`
url_array['公式：公式计算'] = `${url_}/admin/mathjax/mathjax.php`
url_array['公式：公式详情'] = `${url_}/admin/mathjax/getinfo_mathjax.php`
url_array['公式：创建公式'] = `${url_}/admin/mathjax/create_mathjax.php`
url_array['公式：修改公式'] = `${url_}/admin/mathjax/update_mathjax.php`
url_array['公式：删除公式'] = `${url_}/admin/mathjax/delete_mathjax.php`
url_array['打印：打印'] = `${url_}/admin/dayin/getprint.php`
url_array['打印：获取回填数据'] = `${url_}/admin/dayin/getdata.php`
url_array['三个模板：保存数据列表'] = `${url_}/admin/moban/biao/save_moban_data.php`
url_array['检测报告：获取原始记录单检测数据'] = `${url_}/admin/report/get_yuanshijiludan_pushdata.php`
url_array['检测报告：重新获取模板表格'] = `${url_}/admin/report/get_newtpl_table.php`
url_array['结果录入：获取检测项目检测环境'] = `${url_}/admin/jiancebaogao/getjcitemhuanjing.php`
url_array['结果录入：获取检测项目仪器列表'] = `${url_}/admin/originalRecord/get_js_item_yiqilist.php`
url_array['检测报告模板：删除循环区域内容'] = `${url_}/admin/report/delete_loop_info.php`
url_array['检测报告模板：修改循环区域内容'] = `${url_}/admin/report/update_loop_info.php`
url_array['检测报告模板：获取循环区域列表'] = `${url_}/admin/report/get_loop_list.php`
url_array['检测报告模板：创建循环区域内容'] = `${url_}/admin/report/create_loop_info.php`
url_array['检测报告模板：获取循环区域'] = `${url_}/admin/report/get_loop_info.php`
url_array['结果录入：获取检测过程结果'] = `${url_}/admin/course/get_data.php`
url_array['结果录入：保存检测过程结果'] = `${url_}/admin/course/create_data.php`
url_array['结果录入：上传检测过程表格'] = `${url_}/admin/course/upload_table.php`
url_array['录入检测过程结果：获取仪器数据'] = `${url_}/admin/jiancebaogao/getcheckvalue.php`
url_array['录入检测过程结果：获取检测任务数据'] = `${url_}/admin/task_check/get_task_check_data.php`
url_array['录入检测过程结果：获取检测任务信息'] = `${url_}/admin/task_check/get_task_check_info.php`
url_array['检测项目模板：修改回填数据排序'] = `${url_}/admin/jiancebaogao/update_jc_item_check_data_order.php`
url_array['检测项目模板：修改回填数据'] = `${url_}/admin/jiancebaogao/update_jc_item_check_data.php`
url_array['检测项目模板：获取回填数据列表'] = `${url_}/admin/jiancebaogao/getlist_jc_item_check_data.php`
url_array['检测项目模板：获取回填数据信息'] = `${url_}/admin/jiancebaogao/getinfo_jc_item_check_data.php`
url_array['检测项目模板：删除回填数据'] = `${url_}/admin/jiancebaogao/delete_jc_item_check_data.php`
url_array['检测项目模板：创建回填数据'] = `${url_}/admin/jiancebaogao/create_jc_item_check_data.php`

url_array['三个模板：获取模板表格'] = `${url_}/admin/moban/biao/getinfo_moban_table.php`
url_array['三个模板：获取模板信息'] = `${url_}/admin/moban/biao/getinfo_moban.php`
url_array['三个模板：上传模板'] = `${url_}/admin/moban/biao/upload_moban.php`
url_array['三个模板：修改模板'] = `${url_}/admin/moban/biao/update_moban.php`
url_array['三个模板：修改模板数据'] = `${url_}/admin/moban/biao/update_moban_data.php`
url_array['三个模板：获取模板列表'] = `${url_}/admin/moban/biao/getlist_moban.php`
url_array['三个模板：获取模板数据列表'] = `${url_}/admin/moban/biao/getlist_moban_data.php`
url_array['三个模板：获取模板数据信息选项'] = `${url_}/admin/moban/biao/getlist_moban_data_type.php`
url_array['三个模板：获取模板数据信息'] = `${url_}/admin/moban/biao/getinfo_moban_data.php`
url_array['三个模板：删除模板'] = `${url_}/admin/moban/biao/delete_moban.php`
url_array['三个模板：删除模板数据'] = `${url_}/admin/moban/biao/delete_moban_data.php`
url_array['三个模板：创建模板'] = `${url_}/admin/moban/biao/create_moban.php`
url_array['三个模板：创建模板数据'] = `${url_}/admin/moban/biao/create_moban_data.php`

url_array['检测项目：修改环境'] = `${url_}/admin/item/edit_ex_environ.php`
url_array['检测项目：删除环境'] = `${url_}/admin/item/del_ex_environ.php`
url_array['检测项目：添加环境'] = `${url_}/admin/item/add_ex_environ.php`
url_array['检测项目：环境列表'] = `${url_}/admin/item/get_ex_environ.php`
url_array['委托单：负责人列表'] = `${url_}/admin/task/fuzeren_list.php`
url_array['检测报告：获取仪器回填数据'] = `${url_}/admin/report/get_yiqi_value.php`
url_array['检测报告：仪器回填数据列表'] = `${url_}/admin/report/get_yiqi_data_list.php`
url_array['原始记录单：获取原始记录单模板自定义数据列表'] = `${url_}/admin/task_check/get_task_check_item.php`

url_array['检测报告模板：删除仪器回填节点'] = `${url_}/admin/report/del_report_yiqi.php`
url_array['检测报告模板：创建仪器回填节点'] = `${url_}/admin/report/create_report_yiqi.php`
url_array['检测报告模板：获取仪器回填列表'] = `${url_}/admin/report/get_report_yiqi.php`


url_array['原始记录单：检测流程读取模板仪器数据'] = `${url_}/admin/task_check/get_yiqitpl_value.php`
url_array['原始记录单：获取模板仪器回填列表'] = `${url_}/admin/task_check/get_yiqitpl_out_data.php`

url_array['原始记录单模板：删除仪器回填节点'] = `${url_}/admin/originalRecord/del_original_record_yiqi.php`
url_array['原始记录单模板：创建仪器回填节点'] = `${url_}/admin/originalRecord/create_original_record_yiqi.php`
url_array['原始记录单模板：获取仪器回填列表'] = `${url_}/admin/originalRecord/get_original_record_yiqi.php`
url_array['检测报告：获取模板结构'] = `${url_}/admin/report/get_tpl_table.php`
url_array['检测报告：检测报告模板列表'] = `${url_}/admin/report/report_tpl_list.php`
url_array['检测报告：创建检测报告'] = `${url_}/admin/jiancebaogao/chuangjianjiancebaogao.php`
url_array['检测报告：在操作之前确认状态'] = `${url_}/admin/jiancebaogao/jiancebaogaozhiqianjiancha.php`
url_array['样品管理：获取编号列表'] = `${url_}/admin/ljy/get_rulenum.php`
url_array['样品管理：获取样品编号'] = `${url_}/admin/ljy/get_serialnumbers.php`
url_array['样品管理：获取样品默认编号'] = `${url_}/admin/task/get_yp_number_default.php`
url_array['样品管理：检测项目列表'] = `${url_}/admin/ljy/jcitem_list.php`
url_array['样品管理：获取样品检测项目'] = `${url_}/admin/task_check/get_task_check.php`
url_array['样品管理：保存检测项目'] = `${url_}/admin/weituodan/yangpinjianceguanli.php`
url_array['样品管理：校验人员列表'] = `${url_}/admin/weituodan/jiaoyanren.php`
url_array['样品管理：审核人员列表'] = `${url_}/admin/weituodan/shenheren.php`
url_array['样品管理：检测人员列表'] = `${url_}/admin/weituodan/jianceren.php`
url_array['样品管理：样品列表'] = `${url_}/admin/weituodan/yangpinlist.php`
url_array['样品管理：编辑样品信息'] = `${url_}/admin/weituodan/bianjiyangpin.php`
url_array['样品管理：样品信息'] = `${url_}/admin/weituodan/yangpininfo.php`
url_array['样品管理：添加样品'] = `${url_}/admin/weituodan/chuangjianyangpin.php`
url_array['样品管理：任务列表'] = `${url_}/admin/weituodan/weituodan_list.php`
url_array['样品管理：采集人列表'] = `${url_}/admin/weituodan/caiji_list.php`

url_array['原始记录单流程：提交审核'] = `${url_}/admin/task_check/tocheck.php`
url_array['检测报告流程，报告提交审核'] = `${url_}/admin/report/report_to_check.php`
url_array['检测报告流程，获取保存内容'] = `${url_}/admin/report/get_report_save_data.php`
url_array['检测报告流程，保存报告'] = `${url_}/admin/report/save_report_data.php`
url_array['检测报告流程，打印预览'] = `${url_}/admin/report/get_print_data.php`
url_array['检测报告流程，获取原始记录单数据'] = `${url_}/admin/report/get_or_set_data.php`
url_array['检测报告流程，获取动态数据'] = `${url_}/admin/report/get_report_tpl_data.php`
url_array['检测报告流程，获取表格模板'] = `${url_}/admin/report/get_report_tpl_info.php`
url_array['检测报告模板流程删除报告模板单元格数据'] = `${url_}/admin/report/del_check_item_data.php`
url_array['检测报告模板流程获取报告模板单元格数据'] = `${url_}/admin/report/get_check_item_data_list.php`
url_array['检测报告模板流程保存报告模板单元格数据'] = `${url_}/admin/report/save_check_item_data.php`
url_array['检测报告模板流程获取检测项目模板数据'] = `${url_}/admin/report/get_check_item_data.php`
url_array['检测报告模板流程获取检测项目列表'] = `${url_}/admin/report/get_check_item_list.php`
url_array['原始记录单删除单元格'] = `${url_}/admin/originalRecord/del_original_record_item.php`
url_array['原始记录单获取单元格'] = `${url_}/admin/originalRecord/get_original_record_item.php`
url_array['原始记录单编辑单元格'] = `${url_}/admin/originalRecord/create_original_record_item.php`
url_array['检测流程获取保存的数据表'] = `${url_}/admin/task_check/get_save_content.php`
url_array['检测流程保存检测完成'] = `${url_}/admin/task_check/get_save_data.php`
url_array['检测流程获生成打印'] = `${url_}/admin/task_check/get_print_data.php`
url_array['检测流程获取原始记录单配置数据'] = `${url_}/admin/task_check/get_tpl_set_data.php`
url_array['检测流程获取检测项目公式信息'] = `${url_}/admin/task_check/getgongshi.php`
url_array['检测流程读取仪器数据'] = `${url_}/admin/task_check/get_yiqi_value.php`
url_array['检测流程获取检测样品信息'] = `${url_}/admin/task_check/check_yangpin_info.php`
url_array['检测流程获取仪器设置'] = `${url_}/admin/task_check/get_yiqi_out_data.php`
url_array['检测流程获取样品区域'] = `${url_}/admin/task_check/get_yangpin_area_table.php`
url_array['开始检测检查环境'] = `${url_}/admin/task_check/start_check.php`


url_array['保存仪器公式信息0309'] = `${url_}/admin/shebei/savegongshi.php`
url_array['获取仪器公式信息0309'] = `${url_}/admin/shebei/getgongshi.php`
url_array['保存仪器表格信息0309'] = `${url_}/admin/shebei/savetable.php`
url_array['获取仪器表格信息0309'] = `${url_}/admin/shebei/gettable.php`

url_array['获取样品设备读取信息'] = `${url_}/admin/item/getyqinfo.php`
url_array['保存样品设备读取信息'] = `${url_}/admin/item/saveyq.php`
url_array['获取样品设备多层选择列表'] = `${url_}/admin/item/getyq.php`
url_array['获取样品设置区域和内容'] = `${url_}/admin/item/getarea.php`
url_array['保存检测项目公式信息'] = `${url_}/admin/item/savegongshi.php`
url_array['获取检测项目公式信息'] = `${url_}/admin/item/getgongshi.php`

url_array['保存检测项目表格信息'] = `${url_}/admin/item/savetable.php`
url_array['获取检测项目表格信息'] = `${url_}/admin/item/gettable.php`
url_array['检测项目保存实验描述'] = `${url_}/admin/item/savedescribed.php`
url_array['检测项目绑定原始记录单模板'] = `${url_}/admin/originalRecord/jc_item_save_ortpl.php`

url_array['获取任务检测项目0302'] = `${url_}/admin/task/get_task_item_arr.php`
url_array['保存检测项目0302'] = `${url_}/admin/task/save_task_item.php`
url_array['生成任务检测列表0302'] = `${url_}/admin/task/get_task_item_list.php`
url_array['获取选择的检测项目列表0302'] = `${url_}/admin/task/get_choose_item_list.php`
url_array['样品类型检测项目列表0302'] = `${url_}/admin/task/get_check_item_list.php`
url_array['样品类型列表0302'] = `${url_}/admin/task/get_check_type_list.php`
url_array['全部样品列表'] = `${url_}/admin/spec/group_info_all.php`

url_array['保存检测报告表'] = `${url_}/admin/report/save_report_table.php`
url_array['检测报告第三阶段获取计算数据'] = `${url_}/admin/report/get_report_data_push.php`
url_array['检测报告第二阶段获取回填内容'] = `${url_}/admin/report/get_report_data_return.php`
url_array['获取检测报告第一阶段的表格内容'] = `${url_}/admin/report/get_report_table.php`

url_array['删除报告返回数据'] = `${url_}/admin/report/del_push_data.php`
url_array['编辑报告返回数据'] = `${url_}/admin/report/edit_push_data.php`
url_array['获取报告返回数据'] = `${url_}/admin/report/get_push_data.php`
url_array['设置报告返回数据'] = `${url_}/admin/report/set_push_data.php`

url_array['删除报告数据'] = `${url_}/admin/report/del_tpl_table_data.php`
url_array['获取报告数据列表'] = `${url_}/admin/report/get_tpl_table_data_list.php`
url_array['创建报告数据'] = `${url_}/admin/report/save_tpl_data.php`
url_array['获取报告数据'] = `${url_}/admin/report/get_tpl_table_data.php`
url_array['报告数据类型列表'] = `${url_}/admin/report/data_type_list.php`

url_array['删除报告模板区域'] = `${url_}/admin/report/del_tpl_area.php`
url_array['设置报告模板区域'] = `${url_}/admin/report/save_tpl_area.php`
url_array['获取报告模板区域设置'] = `${url_}/admin/report/get_tpl_area.php`

url_array['保存报告模板内容'] = `${url_}/admin/report/save_tpl_table.php`
url_array['获取报告模板表格结构数据'] = `${url_}/admin/report/get_tpl_table.php`
url_array['修改报告模板基本信息'] = `${url_}/admin/report/update_tpl.php`
url_array['报告模板基本资料'] = `${url_}/admin/report/get_tpl_info.php`
url_array['报告模板分页列表'] = `${url_}/admin/report/tpl_list.php`
url_array['创建报告模板'] = `${url_}/admin/report/create_tpl.php`


url_array['任务:编制人列表'] = `${url_}/admin/task/bianzhi_list.php`
url_array['任务:审核人列表'] = `${url_}/admin/task/shenhe_list.php`
url_array['任务:校验人列表'] = `${url_}/admin/task/xiaoyan_list.php`
url_array['获取公式计算数据'] = `${url_}/admin/to_check/get_gs_data.php`
url_array['获取检测数据'] = `${url_}/admin/to_check/get_check_data.php`
url_array['获取原始记录单样品配置'] = `${url_}/admin/to_check/get_yp_config.php`

url_array['获取公式列表'] = `${url_}/admin/originalRecord/get_meca_list.php`
url_array['保存样品区域设置'] = `${url_}/admin/originalRecord/save_yp_set.php`
url_array['获取样品区域设置'] = `${url_}/admin/originalRecord/get_yp_set.php`

url_array['临时查看需不需要检测资质'] = `${url_}/admin/test/det_power_check.php`
url_array['临时检测方法列表'] = `${url_}/admin/test/det_func_list.php`
url_array['临时检测项目列表'] = `${url_}/admin/test/det_item_list.php`

url_array['打印这些核酸报告'] = `${url_}/admin/sHesuan/get_print_data.php`
url_array['删除核酸检测模板动态数据'] = `${url_}/admin/sHesuan/del_tpl_data.php`
url_array['获取核酸检测模板动态数据列表'] = `${url_}/admin/sHesuan/get_tpl_data_list.php`
url_array['获取核酸检测模板动态数据'] = `${url_}/admin/sHesuan/get_tpl_data.php`
url_array['保存核酸检测模板动态数据'] = `${url_}/admin/sHesuan/save_tpl_data.php`
url_array['核酸检测模板动态数据类型'] = `${url_}/admin/sHesuan/data_type_list.php`
url_array['获取核酸检测模板'] = `${url_}/admin/sHesuan/get_tpl.php`
url_array['保存核酸检测模板'] = `${url_}/admin/sHesuan/save_tpl.php`

url_array['删除检测任务模板动态表格静态数据'] = `${url_}/admin/check/del_check_table.php`
url_array['获取检测任务模板动态表格静态数据'] = `${url_}/admin/check/get_check_table.php`
url_array['保存检测任务模板动态表格静态数据'] = `${url_}/admin/check/save_check_table.php`
url_array['获取检测任务模板动态表格数据回填'] = `${url_}/admin/check/data_type_list_data.php`
url_array['获取检测任务模板动态表格数据类型'] = `${url_}/admin/check/data_type_list.php`
url_array['获取检测任务模板动态表格数据列表'] = `${url_}/admin/check/get_tpl_table_data_list.php`
url_array['获取检测任务模板动态表格结构'] = `${url_}/admin/check/get_tpl_table.php`
url_array['修改检测任务模板'] = `${url_}/admin/check/save_check_tpl.php`
url_array['获取检测任务模板列表'] = `${url_}/admin/check/get_tpl_list.php`
url_array['获取检测任务信息'] = `${url_}/admin/check/get_check_info.php`


url_array['删除原始报告单数据'] = `${url_}/admin/originalRecord/del_tpl_table_data.php`
url_array['获取原始报告单数据列表'] = `${url_}/admin/originalRecord/get_tpl_table_data_list.php`
url_array['创建原始报告单数据'] = `${url_}/admin/originalRecord/save_tpl_data.php`
url_array['获取原始报告单数据'] = `${url_}/admin/originalRecord/get_tpl_table_data.php`
url_array['原始报告单数据类型列表'] = `${url_}/admin/originalRecord/data_type_list.php`
url_array['删除模板区域'] = `${url_}/admin/originalRecord/del_tpl_area.php`
url_array['设置模板区域'] = `${url_}/admin/originalRecord/save_tpl_area.php`
url_array['获取模板区域设置'] = `${url_}/admin/originalRecord/get_tpl_area.php`
url_array['保存原始记录单模板内容'] = `${url_}/admin/originalRecord/save_tpl_table.php`
url_array['获取原始记录单表格结构数据'] = `${url_}/admin/originalRecord/get_tpl_table.php`
url_array['修改原始记录单模板基本信息'] = `${url_}/admin/originalRecord/update_tpl.php`
url_array['原始记录单模板基本资料'] = `${url_}/admin/originalRecord/get_tpl_info.php`
url_array['原始记录单模板分页列表'] = `${url_}/admin/originalRecord/tpl_list.php`
url_array['创建原始记录单模板'] = `${url_}/admin/originalRecord/create_tpl.php`
url_array['删除表格数据'] = `${url_}/admin/check/del_sample_table_data.php`
url_array['表格数据列表'] = `${url_}/admin/check/get_sample_table_data_list.php`
url_array['创建修改单元格数据'] = `${url_}/admin/check/save_sample_table_data.php`
url_array['数据类型列表'] = `${url_}/admin/check/data_type_list.php`
url_array['获取数据信息'] = `${url_}/admin/check/get_sample_table_data.php`
url_array['创建模板'] = `${url_}/admin/check/create_sample_table_tpl.php`
url_array['获取表格数据'] = `${url_}/admin/check/get_sample_table.php`
url_array['保存样品表格'] = `${url_}/admin/check/save_sample_table.php`
url_array['表格模板列表'] = `${url_}/admin/check/get_tpl_list.php`
url_array['负责人列表'] = `${url_}/admin/task/principalper_list.php`
url_array['采集和接收人获取和自己相关的任务'] = `${url_}/admin/spec/task_list.php`
url_array['删除任务检测'] = `${url_}/admin/task/del_check_item.php`
url_array['修改任务检测'] = `${url_}/admin/task/edit_task_check.php`
url_array['任务检测列表'] = `${url_}/admin/task/task_check_list.php`
url_array['添加任务检测'] = `${url_}/admin/task/add_new_check.php`
url_array['核对人员列表'] = `${url_}/admin/task/readper_list.php`
url_array['能力ID获取检测人员列表'] = `${url_}/admin/task/checkper_list.php`
url_array['样品项目方法获取检测能力'] = `${url_}/admin/task/det_bind_info.php`
url_array['根据样品ID项目获取检测方法列表'] = `${url_}/admin/task/det_func_list.php`
url_array['根据样品ID获取项目列表'] = `${url_}/admin/task/det_item_list.php`
url_array['删除样品'] = `${url_}/admin/task/del_spec_item.php`
url_array['修改样品'] = `${url_}/admin/task/edit_sample.php`
url_array['任务样品列表'] = `${url_}/admin/spec/group_info.php`
url_array['添加样品'] = `${url_}/admin/task/add_new_sample.php`
url_array['批量生成选择编号'] = `${url_}/admin/ljy/get_serialnumbers.php`
url_array['获取样品的默认ID'] = `${url_}/admin/task/get_yp_number_default.php`
url_array['采集员列表'] = `${url_}/admin/task/pickper_list.php`
url_array['样品类型列表'] = `${url_}/admin/task/sample_types_list.php`
url_array['修改委托单'] = `${url_}/admin/task/update_task_content.php`
url_array['模板详情'] = `${url_}/admin/task/get_tpl_info.php`
url_array['委托单模板'] = `${url_}/admin/task/get_tpl_list.php`
url_array['创建委托单'] = `${url_}/admin/task/create_new_task.php`
url_array['委托单详情'] = `${url_}/admin/task/get_task_info.php`
url_array['创建新预委托单'] = `${url_}/admin/task/create_new_before.php`
url_array['委托单分页列表'] = `${url_}/admin/task/task_list_page.php`
url_array['任务检测内容'] = `${url_}/admin/task/task_spec_info.php`
url_array['编辑任务检测内容'] = `${url_}/admin/task/edit_task_spec.php`
url_array['获取检测项目所有分级列表'] = `${url_}/admin/ljy/detitemalllist.php`
url_array['任务绑定样品组'] = `${url_}/admin/task/task_bind_group.php`
url_array['纯洁的样品组列表'] = `${url_}/admin/spec/no_task_group_list.php`
url_array['绑定过样品组的草稿列表'] = `${url_}/admin/task/has_group_before_list.php`
url_array['任务自定义表格信息'] = `${url_}/admin/task/task_table_info.php`
url_array['任务自定义表格'] = `${url_}/admin/task/edit_task_table.php`
url_array['获取模板内容'] = `${url_}/admin/ljy/get_templatecontent.php`
url_array['修改任务基础信息'] = `${url_}/admin/task/update_task.php`
url_array['任务信息'] = `${url_}/admin/task/task_info.php`
url_array['任务列表'] = `${url_}/admin/task/task_list.php`
url_array['创建任务'] = `${url_}/admin/task/create_task.php`
url_array['获取是否有权限'] = `${url_}/admin/danda/get_auth_detail.php`
url_array['批量生成默认编号'] = `${url_}/admin/ljy/get_default_serialnumbers.php`
url_array['请求编号接口'] = `${url_}/admin/ljy/get_default_serialnumber.php`
url_array['获取部门人员分级列表'] = `${url_}/admin/ljy/dandalist.php`
url_array['预委托单自定义信息'] = `${url_}/admin/task/before_table_info.php`
url_array['预委托单自定义信息修改'] = `${url_}/admin/task/edit_before_table.php`
url_array['修改预委托单'] = `${url_}/admin/task/update_before_task.php`
url_array['预委托单信息'] = `${url_}/admin/task/before_info.php`
url_array['创建预任务单'] = `${url_}/admin/task/create_before_task.php`
url_array['预委托单列表'] = `${url_}/admin/task/before_list.php`
url_array['修改样品组'] = `${url_}/admin/spec/update_group.php`
url_array['样品组列表'] = `${url_}/admin/spec/group_list.php`
url_array['样品组信息'] = `${url_}/admin/spec/group_info.php`
url_array['样品组创建'] = `${url_}/admin/spec/create_group.php`
url_array['公式列表'] = `${url_}/admin/ljy/formulalist.php`
url_array['获取样品类型'] = `${url_}/admin/ljy/samplecategorylist.php`
url_array['获取基础字段全部列表'] = `${url_}/admin/ljy/sampletypelist.php`
url_array['检测方法分级列表'] = `${url_}/admin/ljy/methodalllist.php`
url_array['获取检测项目分级列表'] = `${url_}/admin/ljy/detitemlist.php`
url_array['获取样品类型所有分级列表'] = `${url_}/admin/ljy/samplecategoryalllist.php`
url_array['删除检测能力库'] = `${url_}/admin/ljy/del_bindsample.php`
url_array['创建检测能力库'] = `${url_}/admin/ljy/create_bindsample.php`
url_array['修改检测能力库'] = `${url_}/admin/ljy/edit_bindsample.php`
url_array['根据Id获取能力详情'] = `${url_}/admin/ljy/get_bindsample_detail.php`
url_array['获取检测能力库所有分级列表'] = `${url_}/admin/ljy/bindsamplealllist.php`
url_array['删除检测方法'] = `${url_}/admin/ljy/del_detmethod.php`
url_array['获取文档所有列表'] = `${url_}/admin/ljy/documentlist.php`
url_array['修改检测方法'] = `${url_}/admin/ljy/edit_detmethod.php`
url_array['根据Id获取检测方法详情'] = `${url_}/admin/ljy/get_detmethod_detail.php`
url_array['创建检测方法'] = `${url_}/admin/ljy/create_detmethod.php`
url_array['根据id获取检测项目详情'] = `${url_}/admin/ljy/get_detitem_detail.php`
url_array['用样品ID获取检测项目'] = `${url_}/admin/ljy/get_itemlistbyid.php`
url_array['根据样品获取项目列表'] = `${url_}/admin/ljy/get_itemlist.php`
url_array['获取样品列表'] = `${url_}/admin/ljy/get_samplelist.php`
url_array['上传图片'] = `${url_}/admin/ljy/upload_pic.php`
url_array['删除委托单模板'] = `${url_}/admin/tpl/del_tpl.php`
url_array['修改委托单模板'] = `${url_}/admin/tpl/update_tpl.php`
url_array['创建委托单模板'] = `${url_}/admin/tpl/create_tpl.php`
url_array['根据id获取委托单详情'] = `${url_}/admin/ljy/get_template_detail.php`
url_array['获取委托单分级列表'] = `${url_}/admin/ljy/templatealllist.php`
url_array['删除委托单字段'] = `${url_}/admin/task/del_form_word.php`
url_array['编辑委托单字段'] = `${url_}/admin/task/edit_form_word.php`
url_array['创建委托单字段'] = `${url_}/admin/task/create_form_word.php`
url_array['根据id获取委托单字段详情'] = `${url_}/admin/task/get_form_word_detail.php`
url_array['获取委托单字段'] = `${url_}/admin/task/get_form_word.php`
url_array['获取委托单字段分级列表'] = `${url_}/admin/ljy/formwordlist.php`
export default url_array;
