<template>
    <div class="home-container">
        <div class="gogngao-container">
            <div class="ggtitle"></div>
            <div class="ggtitletext">
                <h2>通知公告</h2>
                <span>announcement</span>
            </div>
            <div class="ggwraptext">
                <div v-for="( item,key) in tongzhitableData" :key="key" v-if="key===0">
                    {{item.content}}
                    <span class="fr">{{item.create_time}}</span></div>
            </div>
        </div>
        <div class="home3_main">
            <div class="home3_main-left">
                <a-tabs @change="callback" v-model="tab_active">
                    <a-tab-pane :key="item.id" :tab="item.title" v-for="(item,key) in list_authdata"></a-tab-pane>
                </a-tabs>
                <div v-if="Number(tab_active) === 176">
                    <table class="layui-table" lay-size="sm" style="margin-top: -5px;">
                        <thead>
                        <tr>
                            <th>样品名称</th>
                            <th>样品编号</th>
                            <th>样品状态</th>
                            <th>采集人</th>
                            <th>采集地点</th>
                            <th>操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="( item,key) in list_tabledata" :key="key" v-if="key<15">
                            <td>{{item.name}}</td>
                            <td>{{item.in_number}}</td>
                            <td>{{item.status_trun}}</td>
                            <td>{{item.caijiren}}</td>
                            <td>{{item.pick_space}}</td>
                            <td>
                                <a @click="showclick1(item.task_id)">查看</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="Number(tab_active) === 178">
                    <table class="layui-table" lay-size="sm" style="margin-top: -5px;">
                        <thead>
                        <tr>
                            <th>样品名称</th>
                            <th>样品编号</th>
                            <th>样品状态</th>
                            <th>采集人</th>
                            <th>采集地点</th>
                            <th>操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="( item,key) in list_tabledata" :key="key" v-if="key<15">
                            <td>{{item.name}}</td>
                            <td>{{item.in_number}}</td>
                            <td>{{item.status_trun}}</td>
                            <td>{{item.caijiren}}</td>
                            <td>{{item.pick_space}}</td>
                            <td><a @click="showclick2(item.task_id)">查看</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>


                <div v-if="Number(tab_active) === 231">
                    <table class="layui-table" lay-size="sm" style="margin-top: -5px;">
                        <thead>
                        <tr>
                            <th>检验项目</th>
                            <th>样品编号</th>
                            <th>检验状态</th>
                            <th>检验标准</th>
                            <th>操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="( item,key) in list_tabledata" :key="key" v-if="key<15">
                            <td>{{item.name}}</td>
                            <td>{{item.in_number}}</td>
                            <td>{{item.state_turn}}</td>
                            <td>{{item.check_bz_name}}</td>
                            <td><a @click="showclick3(item.sample_id)">查看</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="Number(tab_active) === 225">
                    <table class="layui-table" lay-size="sm" style="margin-top: -5px;">
                        <thead>
                        <tr>
                            <th>检验项目</th>
                            <th>样品编号</th>
                            <th>检验方法</th>
                            <th>检验状态</th>
                            <th>操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="( item,key) in list_tabledata" :key="key" v-if="key<15">
                            <td>{{item.item_name}}</td>
                            <td>{{item.inter_code}}</td>
                            <td>{{item.method_name}}</td>
                            <td>{{item.state_turn}}</td>
                            <td><a @click="showclick4(item.inter_code,item.item_name)">查看</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="Number(tab_active) === 226">
                    <table class="layui-table" lay-size="sm" style="margin-top: -5px;">
                        <thead>
                        <tr>
                            <th>检验项目</th>
                            <th>样品编号</th>
                            <th>检验方法</th>
                            <th>检验状态</th>
                            <th>操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="( item,key) in list_tabledata" :key="key" v-if="key<15">
                            <td>{{item.item_name}}</td>
                            <td>{{item.inter_code}}</td>
                            <td>{{item.method_name}}</td>
                            <td>{{item.state_turn}}</td>
                            <td><a @click="showclick5(item.inter_code, item.item_name)">查看</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="Number(tab_active) === 240">
                    <table class="layui-table" lay-size="sm" style="margin-top: -5px;">
                        <thead>
                        <tr>
                            <th>检验项目</th>
                            <th>样品名称</th>
                            <th>样品编号</th>
                            <th>报告状态</th>
                            <th>操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="( item,key) in list_tabledata" :key="key" v-if="key<15">
                            <td>{{item.item_name}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.in_number}}</td>
                            <td>{{item.state_turn}}</td>
                            <td><a @click="showclick6(item.id)">查看</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="Number(tab_active) === 227">
                    <table class="layui-table" lay-size="sm" style="margin-top: -5px;">
                        <thead>
                        <tr>
                            <th>委托单名称</th>
                            <th>报告编号</th>
                            <th>报告状态</th>
                            <th>委托单类型</th>
                            <th>单位名称</th>
                            <th>操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="( item,key) in list_tabledata" :key="key" v-if="key<15">
                            <td>{{item.name}}</td>
                            <td>{{item.task_num}}</td>
                            <td>{{item.state_turn}}</td>
                            <td>{{item.examining_type_trun}}</td>
                            <td>{{item.com_name}}</td>
                            <td><a @click="showclick7(item.task_num)">查看</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="Number(tab_active) === 228">
                    <table class="layui-table" lay-size="sm" style="margin-top: -5px;">
                        <thead>
                        <tr>
                            <th>委托单名称</th>
                            <th>报告编号</th>
                            <th>报告状态</th>
                            <th>单位名称</th>
                            <th>检测类别</th>
                            <th>操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="( item,key) in list_tabledata" :key="key" v-if="key<15">
                            <td>{{item.name}}</td>
                            <td>{{item.task_num}}</td>
                            <td>{{item.state_turn}}</td>
                            <td>{{item.com_name}}</td>
                            <td>{{item.examining_type_trun}}</td>
                            <td><a @click="showclick8(item.task_num)">查看</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="home3_main-right">
                <div>
                    <div class="newsmain">
                        <div class="newtitle">
                            <a-tabs v-model="active_tab" style="height: 42px" @change="changetab">
                                <a-tab-pane :key="1" tab="未读消息"></a-tab-pane>
                                <a-tab-pane :key="2" tab="已读消息"></a-tab-pane>
                            </a-tabs>
                        </div>
                        <div class="newcontainer">
                            <div class="newlist" v-for="( item,key) in list_noticedata" :key="key" @click="newitemclick(item)">
                                <div class="newlistname">{{item.notice_type_name}}</div>
                                <div class="newlisttime">{{item.create_time}}</div>
                            </div>
                            <div class="newpage">
                                <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--消息弹窗-->
        <a-modal title="消息详情" :visible="visible" @cancel="ihandleCancel" @ok="handleOk">
            <div v-html="`${newinfo.moban_content}<br/>`" class="newmain"></div>
        </a-modal>
        <div class="home3-bottom">
            <div class="home3-bottom-title">近15天工作统计</div>
            <div class="home3-bottom-main">
                <div id="tongjimain" style="width: 100%; height: 500px"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "home3",
        data() {
            return {
                active_tab: 1,
                tab_active: '',
                tongzhitableData: [],
                list_noticedata: [],
                list_authdata: [],
                list_tabledata: [],
                tab_id: 0,
                tab_active1: 1,
                page: 1,
                count: 0,
                pagesize: 0,
                visible: false,
                newinfo: '',

            };
        },
        mounted() {
            this.tongjidata()
            this.Myannouncement() //我的通知
            this.Home_list_notice(1)
            this.Home_list_auth()
            this.Home_list_table()


        },
        methods: {
            showclick1(task_id) {
                this.$router.push(`/sampleguanli/caijiauth/0?id=${task_id}`);
            },
            showclick2(task_id) {
                this.$router.push(`/sampleguanli/caijiauth/0?id=${task_id}`);
            },
            showclick3(spec_item_id) {
                this.$router.push(`/resultinput/resultinput?id=${spec_item_id}`);
            },
            showclick4(inter_code, item_name) {
                this.$router.push(`/Checklist/Checklist/0?inter_code=${inter_code}&item_name=${item_name}&method_name=&state=&starttime=&endtime=`);
            },
            showclick5(inter_code, item_name) {
                this.$router.push(`/shenhelist/shenhelist/0?inter_code=${inter_code}&item_name=${item_name}&method_name=&state=`);
            },
            showclick6(id) {
                this.$router.push(`/baogaobianzhi/baogaobianzhi?id=${id}`);
            },
            showclick7(task_num) {
                this.$router.push(`/baogaolist/baogaolist/0?task_num=${task_num}`);
            },
            showclick8(task_num) {
                this.$router.push(`/baogaolistshenhe/baogaolistshenhe/0?task_num=${task_num}`);
            },

            callback(key) {
                this.Home_list_table()
            },
            Home_list_table() {
                this.$sa0.post({
                    url: this.$api('Home_list_table'),
                    data: {
                        id: this.tab_active
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.list_tabledata = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            Home_list_auth() {
                this.$sa0.post({
                    url: this.$api('Home_list_auth'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.list_authdata = response.data.list;
                            if (this.list_authdata.length) {
                                this.tab_active = this.list_authdata[0].id
                                this.callback(this.list_authdata[0].id)
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            changetab(key) {
                this.Home_list_notice1(key);
            },

            Home_list_notice1(stateNumber) {
                this.page = 1
                this.Home_list_notice(stateNumber)
            },
            Home_list_notice(stateNumber) {
                this.$sa0.post({
                    url: this.$api('Home_list_notice'),
                    data: {
                        page: this.page,
                        state: stateNumber
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.list_noticedata = response.data.return.list;
                            this.count = Number(response.data.return.count);//分页
                            this.pagesize = Number(response.data.return.pagesize);//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            onChange(page) {
                this.page = page;
                this.Home_list_notice(this.active_tab)
            },
            newitemclick(item) {
                this.visible = true
                this.newinfo = item
            },
            ihandleCancel(e) {
                this.visible = false;
            },
            handleOk() {
                this.$sa0.post({
                    url: this.$api('home_set_notice'),
                    data: {
                        moban_notice_id: this.newinfo.id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.visible = false
                            layer.msg(response.message)
                            this.Home_list_notice(1)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 我的通知
            Myannouncement() {
                this.$sa0.post({
                    url: this.$api('Myannouncement'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.tongzhitableData = response.data.result.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            tongjidata() {
                this.$sa0.post({
                    url: this.$api('Home_list_auth'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            var chartDom = document.getElementById('tongjimain');
                            var myChart = echarts.init(chartDom);
                            var option;
                            let data = response.data.chart;

                            option = {
                                title: {
                                    text: ''
                                },
                                tooltip: {
                                    trigger: 'axis'
                                },
                                legend: {
                                    data: data.y.name
                                },
                                grid: {
                                    left: '3%',
                                    right: '4%',
                                    bottom: '3%',
                                    containLabel: true
                                },
                                toolbox: {
                                    feature: {
                                        saveAsImage: {
                                            show: false
                                        }
                                    }
                                },
                                xAxis: {
                                    type: 'category',
                                    boundaryGap: false,
                                    data: data.x
                                },
                                yAxis: {
                                    type: 'value'
                                },
                                series: data.y
                            };
                            option && myChart.setOption(option);
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })


            }


        }
    }
</script>

<style scoped>

    .home-container{ height: 100%; background: #f9f9f9; padding: 20px; margin-left: -20px; margin-right: -20px }

    .user-name{ font-size: 20px; font-weight: bold; color: #384a56}

    .user-name span{ font-size: 28px}

    .gogngao-container{ width: 100%; padding: 0; background: #ffffff; margin-top: 20px; border: 1px solid #eeeeee; border-radius: 4px; height: 80px; position: relative}

    .ggtitle{width: 180px;border-top: 78px solid #1890ff;border-right: 50px solid transparent; height: 100px; position: absolute; left: 0; z-index: 0}

    .ggtitletext{ position: absolute; z-index: 99; width: 200px; }

    .ggtitletext h2{ font-size: 20px; color: #ffffff; padding-left: 20px; padding-top: 10px; margin: 0; font-weight: bold}

    .ggtitletext span{ font-size: 14px; color: #ffffff; padding-left: 20px; display: inline-block; opacity: 0.6}

    .ggwraptext{ margin-left: 200px; font-size: 16px; line-height: 80px; padding-right: 20px; color: #333}

    .home3_main{ margin-top: 20px; display: flex}

    .home3_main-left{ flex: 1; background: #ffffff; border: 1px solid #eeeeee; margin-right: 20px; padding: 10px}

    .home3_main-right{ width: 500px; background: #ffffff; border: 1px solid #eeeeee; }

    .newsmain{ width: 100%; display: flex; flex-direction: column; height: 600px; }

    .newcontainer{ flex: 1; overflow-y: auto;}

    .newtitle{ width: 100%; background: #f9f9f9; font-weight: bold; height: 40px}

    .newlist{ width: 100%; display: flex; justify-content: space-between}

    .newlist{ width: 100%; border-bottom: 1px solid #eeeeee; padding: 7px 10px; cursor: pointer}

    .home3-bottom{ width: 100%; background: #ffffff; margin-top: 20px; border: 1px solid #eeeeee;}

    .home3-bottom-title{ font-weight: bold; padding: 10px; font-size: 20px; padding: 20px }

    .home3-bottom-main{ padding: 10px}

    .fr{ float: right}

    .newpage{ margin: 10px}


</style>
