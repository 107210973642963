import sa0LocalStorage from './lib/localStorage';
import post from './lib/post';
import get from './lib/get';
import file from './lib/file';
import down from './lib/down';
import print from './lib/print';
import response from './lib/response';
import h2c from './lib/h2c';

let sa0 = {}
sa0.localStorage = sa0LocalStorage;
sa0.post = post;
sa0.print = print;
sa0.h2c = h2c;
sa0.get = get;
sa0.down = down;
sa0.file = file;
sa0.response = response;
export default sa0;
