import axios from 'axios'
import config from '../../config.js'
import member from '../../tool/member'
import store from '../../store/store.js'

const down = axios.create({
    timeout: config.postTimeout
})
let down_info = 'down.xlsx'
down.interceptors.request.use(
    config => {
        store.state.loading = store.state.loading + 1
        config.headers['Authorization'] = 'Bearer ' + (member.getToken() ? member.getToken() : '')
        config.method = 'post'
        down_info = config.file_name
        config.responseType = 'blob'
        return config
    },
    error => {
        console.log('error')
        Promise.reject(error)
    }
)
down.interceptors.response.use(
    response => {
        store.state.loading = store.state.loading - 1
        const blob = new Blob([response.data]);//处理文档流
        const fileName = down_info;
        const elink = document.createElement('a');
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
    },
    error => {
        layer.msg('服务器异常')
        store.state.loading = store.state.loading - 1
        Promise.reject(error)
    }
)
export default down;
