const url_ = APIURL;
let url_array = {};

url_array['Get_executive_bz_list'] = `${url_}/admin/ljy/get_executive_bz_list.php` //执行标准自动完成
url_array['AutoGet_task'] = `${url_}/admin/task/get_task.php` //委托单自动完成查询
url_array['Get_spec_item1'] = `${url_}/admin/weituodan/get_spec_item.php` //样品自动完成
url_array['home_set_notice'] = `${url_}/admin/home/set_notice.php` //设置消息状态
url_array['Copy_template'] = `${url_}/admin/template/copy_template.php` //委托单模板上移下移
url_array['Set_template_sort'] = `${url_}/admin/template/set_template_sort.php` //委托单模板上移下移
url_array['Rename_template'] = `${url_}/admin/template/rename_template.php` //委托单模板重命名
url_array['Home'] = `${url_}/admin/home/home.php` //消息
url_array['Home_list_notice'] = `${url_}/admin/home/list_notice.php` //消息
url_array['Home_list_table'] = `${url_}/admin/home/list_table.php` //table列表
url_array['Home_list_auth'] = `${url_}/admin/home/list_auth.php` //列表标题
url_array['Yangpinlist_new'] = `${url_}/admin/weituodan/yangpinlist_new.php` //样品列表新
url_array['Set_form_word_sort'] = `${url_}/admin/form_word/set_form_word_sort.php` //创建副本
url_array['Copy_form_word'] = `${url_}/admin/form_word/copy_form_word.php` //创建副本
url_array['Rename_form_word'] = `${url_}/admin/form_word/rename_form_word.php` //重命名
url_array['Xiaohui_specitem1'] = `${url_}/admin/notice/xiaohui_specitem.php` //销毁
url_array['Tuihui_task_check1'] = `${url_}/admin/notice/tuihui_task_check.php` //退回检验任务
url_array['Tuihui_specitem1'] = `${url_}/admin/notice/tuihui_specitem.php` //退回采集任务
url_array['Xiafa_task_check1'] = `${url_}/admin/notice/xiafa_task_check.php` //下发检测任务
url_array['Xiafa_specitem1'] = `${url_}/admin/notice/xiafa_specitem.php` //下发采集任务
url_array['Home_right'] = `${url_}/admin/home/home_right.php` //近30天 样品类型分布
url_array['Home_left'] = `${url_}/admin/home/home_left.php` //近30天 受理委托单/检验项目/编制报告
url_array['Get_info_num'] = `${url_}/admin/home/get_info.php` //个数
url_array['Del_ex_environ'] = `${url_}/admin/ex_environ/del_ex_environ.php` //删除环境
url_array['Edit_ex_environ'] = `${url_}/admin/ex_environ/edit_ex_environ.php` //修改环境
url_array['Create_ex_environ'] = `${url_}/admin/ex_environ/create_ex_environ.php` //创建环境
url_array['Get_ex_environ'] = `${url_}/admin/ex_environ/get_ex_environ.php` //列表实验环境
url_array['Set_examining_report_all1'] = `${url_}/admin/result/set_examining_report_all.php` //提交撤销
url_array['Get_task_auditcom'] = `${url_}/admin/result/get_task_auditcom.php` //获取审核通过委托单列表
url_array['Get_examining_reportbianzhi'] = `${url_}/admin/result/get_examining_report.php` //委托单对应的样品
url_array['Move_admin_menu'] = `${url_}/admin/danda/move_admin_menu.php` //移动导航
url_array['Get_jc_item1'] = `${url_}/admin/ljy/get_jc_item.php` //创建方法类别副本
url_array['Get_sample_item_all'] = `${url_}/admin/ljy/get_sample_item_all.php` //创建方法类别副本
url_array['Copy_sample_item'] = `${url_}/admin/ljy/copy_sample_item.php` //创建方法类别副本
url_array['Set_sample_category_sort'] = `${url_}/admin/ljy/set_sample_category_sort.php` //创建方法类别副本
url_array['Copy_method_category'] = `${url_}/admin/ljy/copy_method_category.php` //创建方法类别副本
url_array['Set_method_category_sort'] = `${url_}/admin/ljy/set_method_category_sort.php` //上移下移
url_array['Copy_danda'] = `${url_}/admin/danda/copy_danda.php` //创建权限副本
url_array['Copy_auth'] = `${url_}/admin/danda/copy_auth.php` //创建权限副本
url_array['Copy_executive_bz'] = `${url_}/admin/ljy/copy_executive_bz.php` //创建检测副本
url_array['Copy_check_bz'] = `${url_}/admin/ljy/copy_check_bz.php` //创建执行副本
url_array['Set_dandasort'] = `${url_}/admin/danda/set_dandasort.php` //部门上移下移
url_array['Set_auth_sort'] = `${url_}/admin/ljy/set_auth_sort.php` //权限上移下移
url_array['Set_method_category_sort'] = `${url_}/admin/ljy/set_method_category_sort.php` //方法类别上移下移
url_array['Set_detitemsort'] = `${url_}/admin/ljy/set_detitemsort.php` //上移下移
url_array['Get_jc_item_by_id'] = `${url_}/admin/ljy/get_jc_item_by_id.php` //获取分类列表
url_array['Get_examining_report_log1'] = `${url_}/admin/examining_report/get_examining_report_log.php` //报告操作日志
url_array['Set_examining_report_all'] = `${url_}/admin/examining_report/set_examining_report_all.php` //报告批量设置状态
url_array['Set_examining_report'] = `${url_}/admin/examining_report/set_examining_report.php` //报告设置状态
url_array['Get_examining_report'] = `${url_}/admin/examining_report/get_examining_report.php` //报告列表
url_array['Del_apparatus_parameter'] = `${url_}/admin/ljy/del_apparatus_parameter.php` //删除仪器参数
url_array['Edit_apparatus_parameter'] = `${url_}/admin/ljy/edit_apparatus_parameter.php` //修改仪器参数
url_array['Create_apparatus_parameter'] = `${url_}/admin/ljy/create_apparatus_parameter.php` //创建仪器参数
url_array['Get_apparatus_parameter'] = `${url_}/admin/ljy/get_apparatus_parameter.php` //仪器参数列表
url_array['Set_source_record_all1'] = `${url_}/admin/source_record/set_source_record_all.php` //原始记录单批量设置状态
url_array['Get_source_record_log1'] = `${url_}/admin/source_record/get_source_record_log.php` //原始记录单操作日志
url_array['Set_source_record1'] = `${url_}/admin/source_record/set_source_record.php` //原始记录单设置状态
url_array['Get_source_record1'] = `${url_}/admin/source_record/get_source_record.php` //原始记录单校验列表
url_array['Set_jc_item_curve'] = `${url_}/admin/ljy/set_jc_item_curve.php` //上移下移
url_array['Edit_jc_item_curve'] = `${url_}/admin/ljy/edit_jc_item_curve.php` //修改曲线
url_array['Create_jc_item_curve'] = `${url_}/admin/ljy/create_jc_item_curve.php` //创建曲线
url_array['Get_jc_item_curve_detail'] = `${url_}/admin/ljy/get_jc_item_curve_detail.php` //获取曲线详情
url_array['Rename_jc_item_curve_name'] = `${url_}/admin/ljy/rename_jc_item_curve_name.php` //重命名曲线名称
url_array['Copy_jc_item_curve'] = `${url_}/admin/ljy/copy_jc_item_curve.php` //复制曲线
url_array['Del_jc_item_curve'] = `${url_}/admin/ljy/del_jc_item_curve.php` //删除曲线
url_array['Create_jc_item_curve_name'] = `${url_}/admin/ljy/create_jc_item_curve_name.php` //新增曲线名称
url_array['Get_jc_item_curve'] = `${url_}/admin/ljy/get_jc_item_curve.php` //获取曲线
url_array['Get_jc_item'] = `${url_}/admin/ljy/get_jc_item.php` //曲线列表
url_array['Create_danda_name'] = `${url_}/admin/danda/create_danda_name.php` //新建人员名称
url_array['Rename_danda'] = `${url_}/admin/danda/rename_danda.php` //重命名部门人员
url_array['Rename_auth'] = `${url_}/admin/ljy/rename_auth.php` //重命名权限
url_array['Rename_detitem'] = `${url_}/admin/ljy/rename_detitem.php` //重命名检测项目
url_array['Del_method_category'] = `${url_}/admin/ljy/del_method_category.php` //删除方法类别
url_array['Edit_method_category'] = `${url_}/admin/ljy/edit_method_category.php` //修改方法类别
url_array['Create_method_category'] = `${url_}/admin/ljy/create_method_category.php` //创建方法类别
url_array['Method_category_alllist'] = `${url_}/admin/ljy/method_category_alllist.php` //方法类别列表
url_array['Get_executive_bzlog'] = `${url_}/admin/ljy/get_executive_bzlog.php` //执行标准历史记录
url_array['Del_executive_bz'] = `${url_}/admin/ljy/del_executive_bz.php` //删除执行标准
url_array['Edit_executive_bz'] = `${url_}/admin/ljy/edit_executive_bz.php` //修改执行标准
url_array['Create_executive_bz'] = `${url_}/admin/ljy/create_executive_bz.php` //创建执行标准
url_array['Get_executive_bz'] = `${url_}/admin/ljy/get_executive_bz.php` //执行标准列表
url_array['Set_detmethodfacotry_all'] = `${url_}/admin/ljy/set_detmethodfacotry_all.php` //批量保存因子
url_array['Del_mechanicall'] = `${url_}/admin/ljy/del_mechanicall.php` //删除化学式
url_array['Dp_data'] = `${url_}/admin/ljy/dp_data.php` //大屏当前日期 大屏
url_array['daping_Reagent'] = `${url_}/admin/ljy/daping_reagent.php` //试剂入库出库 大屏
url_array['Daping_report'] = `${url_}/admin/ljy/daping_report.php` //7天报告数量 大屏
url_array['Dp_num'] = `${url_}/admin/ljy/dp_num.php` //今日数量 大屏
url_array['Dp_specitem'] = `${url_}/admin/ljy/dp_specitem.php` //30天采集样品数据
url_array['Factorlist_export'] = `${url_}/admin/ljy/factorlist_export.php` //检测因子导出
url_array['Del_mechanicall'] = `${url_}/admin/ljy/del_mechanicall.php` //删除化学式
url_array['Del_mechanicallyinzi'] = `${url_}/admin/ljy/del_mechanicallyinzi.php` //删除因子
url_array['Save_mechanicallyinzi'] = `${url_}/admin/ljy/save_mechanicallyinzi.php` //编辑因子
url_array['Edit_mechanically'] = `${url_}/admin/ljy/edit_mechanically.php` //修改化学式信息
url_array['Mechanically_detail'] = `${url_}/admin/ljy/mechanically_detail.php` //化学式详情
url_array['Mechanically_export'] = `${url_}/admin/ljy/mechanically_export.php` //导出公式
url_array['Mechanically_upload'] = `${url_}/admin/ljy/mechanically_upload.php` //上传文件公式
url_array['Mechanically_save'] = `${url_}/admin/ljy/mechanically_save.php` //导入公式
url_array['Get_mechanically'] = `${url_}/admin/ljy/get_mechanically.php` //公式列表
url_array['Nucleicshenhe_total'] = `${url_}/admin/ljy/nucleicshenhe_total.php` //核酸检测审核统计
url_array['Nucleicjiance_total'] = `${url_}/admin/ljy/nucleicjiance_total.php` //核酸检测检验统计
url_array['Nucleiccaiyang_total'] = `${url_}/admin/ljy/nucleiccaiyang_total.php` //核酸采样统计
url_array['Myannouncement'] = `${url_}/admin/ljy/myannouncement.php` //创建检测项目副本
url_array['Home_auth'] = `${url_}/admin/danda/home_auth.php` //创建检测项目副本
url_array['Copy_ablility'] = `${url_}/admin/ljy/copy_ablility.php` //创建检测项目副本
url_array['Config'] = `${url_}/admin/ljy/config.php` //创建系统设置
url_array['Get_config'] = `${url_}/admin/ljy/get_config.php` //系统设置获取信息
url_array['Myitemnotice'] = `${url_}/admin/ljy/myitemnotice.php` //我的任务通知
url_array['Get_announcement'] = `${url_}/admin/ljy/get_announcement.php` //获取公告分页列表
url_array['Get_list'] = `${url_}/admin/ljy/get_list.php` //检测人列表
url_array['Edit_pwd'] = `${url_}/admin/ljy/edit_pwd.php` //修改密码
url_array['Apparatus_export'] = `${url_}/admin/ljy/apparatus_export.php` //仪器列表导出
url_array['Set_config'] = `${url_}/admin/ljy/set_config.php` //设置是否显示预警弹窗
url_array['DetmethodByzz'] = `${url_}/admin/ljy/detmethodByzz.php` //根据检测项目获取检测方法
url_array['DetitemByzz'] = `${url_}/admin/ljy/detitemByzz.php` //需要资质的检测项目
url_array['Update_danda_tagurl'] = `${url_}/admin/danda/update_danda_tagurl.php` //修改人员图片
url_array['Upload_pic'] = `${url_}/admin/ljy/upload_pic.php` //上传图片接口
url_array['Adddetitem'] = `${url_}/admin/ljy/adddetitem.php` //创建检测项目
url_array['剔除已经选中的因子列表'] = `${url_}/admin/ljy/factorlist_news.php`
url_array['Set_ablility'] = `${url_}/admin/ljy/set_ablility.php`  //修改因子
url_array['Factorlist'] = `${url_}/admin/ljy/factorlist.php`  //获取所有因子列表
url_array['Del_detmethodfacotry'] = `${url_}/admin/ljy/del_detmethodfacotry.php`  //移除因子
url_array['Set_detmethodfacotry'] = `${url_}/admin/ljy/set_detmethodfacotry.php`  //修改因子
url_array['Sampletypelist'] = `${url_}/admin/ljy/sampletypelist.php`  //获取基础字段全部列表
url_array['Get_detmethodfacotry'] = `${url_}/admin/ljy/get_detmethodfacotry.php`  //获取模板因子列表
url_array['Del_detmethodtemplate'] = `${url_}/admin/ljy/del_detmethodtemplate.php`  //删除模板
url_array['Copy_detmethodtemplate'] = `${url_}/admin/ljy/copy_detmethodtemplate.php`  //复制模板
url_array['Edit_detmethodtemplate'] = `${url_}/admin/ljy/edit_detmethodtemplate.php`  //修改模板
url_array['Create_detmethodtemplate'] = `${url_}/admin/ljy/create_detmethodtemplate.php`  //添加模板
url_array['Get_detmethodtemplate'] = `${url_}/admin/ljy/get_detmethodtemplate.php`  //根据检测方法获取模板
url_array['Edit_det_item'] = `${url_}/admin/ljy/edit_det_item.php`  //设置检测项目执行状态
url_array['Set_detitem'] = `${url_}/admin/ljy/set_detitem.php`  //设置检测项目执行状态
url_array['Edit_ablility'] = `${url_}/admin/ljy/edit_ablility.php`  //修改检测方法
url_array['Documentlist'] = `${url_}/admin/ljy/documentlist.php`  //获取文档所有列表
url_array['Create_ablility'] = `${url_}/admin/ljy/create_ablility.php`  //添加检测项目
url_array['Get_detmethodfactoryByid'] = `${url_}/admin/ljy/get_detmethodfactoryByid.php`  //获取检测方法因子列表
url_array['Get_detitem_detail'] = `${url_}/ljy/get_detitem_detail.php`  //删除检测项目
url_array['Del_detitem'] = `${url_}/admin/ljy/del_detitem.php`  //删除检测项目
url_array['Edit_detitem'] = `${url_}/admin/ljy/edit_detitem.php`  //修改检测项目
url_array['Create_detitem'] = `${url_}/admin/ljy/create_detitem.php`  //创建检测项目
url_array['Get_ablility'] = `${url_}/admin/ljy/get_ablility.php`  //根据检测项目Id获取检测方法
url_array['Getitem_total'] = `${url_}/admin/ljy/getitem_total.php`  //样品接受统计
url_array['Pickitem_total'] = `${url_}/admin/ljy/pickitem_total.php`  //样品采集统计
url_array['Task_total'] = `${url_}/admin/ljy/task_total.php`  //委托单统计
url_array['Daping_specitem'] = `${url_}/admin/ljy/daping_specitem.php`  //大屏样品数据表
url_array['Daping_task'] = `${url_}/admin/ljy/daping_task.php`  //大屏任务委托单数据表
url_array['Set_template'] = `${url_}/admin/ljy/set_template.php`  //设置默认模板
url_array['Set_spec_item_receiver'] = `${url_}/admin/ljy/set_spec_item_receiver.php` //接收人修改采集样品状态
url_array['Set_spec_item'] = `${url_}/admin/ljy/set_spec_item.php`  //修改采集样品状态
url_array['Getspecitembyid'] = `${url_}/admin/ljy/getspecitembyid.php`  //根据组别Id获取样品列表
url_array['Get_spec_group'] = `${url_}/admin/ljy/get_spec_group.php`  //获取人员所在组别
url_array['Loginloglist'] = `${url_}/admin/admin/loginloglist.php`  //获取人员登录日志
url_array['Get_daping'] = `${url_}/admin/ljy/get_daping.php`  //大屏数据
url_array['检测方法所有列表'] = `${url_}/admin/ljy/methodlist.php`//检测方法所有列表
url_array['获取检测项目所有分级列表'] = `${url_}/admin/ljy/detitemalllist.php` //获取检测项目所有分级列表
url_array['Bindsamplelist_qualify'] = `${url_}/admin/ljy/bindsamplelist_qualify.php` // 获取检测能力库需要资质列表
url_array['获取部门人员分级列表'] = `${url_}/admin/ljy/dandaalllist.php` // 根据检测能力Id获取人员资质
url_array['Get_dandaqualifylist'] = `${url_}/admin/danda/get_dandaqualifylist.php` // 根据检测能力Id获取人员资质
url_array['Create_document'] = `${url_}/admin/ljy/create_document.php` // 创建检测标准文档
url_array['Get_dandajob_log'] = `${url_}/admin/danda/get_dandajob_log.php` // 在岗编号记录
url_array['Set_danda_job'] = `${url_}/admin/danda/set_danda_job.php` // 需改人员在岗状态
url_array['Set_danda_auth'] = `${url_}/admin/danda/set_danda_auth.php` // 修改部门人员审核资质权限
url_array['Get_default_serialnumber'] = `${url_}/admin/ljy/get_default_serialnumber.php` // 请求编号接口
url_array['Quanxianlist'] = `${url_}/admin/ljy/quanxianlist.php` // 权限一级列表
url_array['Get_admin_auth'] = `${url_}/admin/ljy/get_admin_auth.php` // 权限列表
url_array['Edit_auth'] = `${url_}/admin/ljy/edit_auth.php` // 修改权限
url_array['Create_auth'] = `${url_}/admin/ljy/create_auth.php` // 根据Id获取权限详情
url_array['Get_auth_detail'] = `${url_}/admin/ljy/get_auth_detail.php` // 根据Id获取权限详情
url_array['Del_auth'] = `${url_}/admin/ljy/del_auth.php` // 删除权限
url_array['Reset_password'] = `${url_}/admin/danda/reset_password.php` // 重置密码
url_array['Create_apparatusinfo'] = `${url_}/admin/ljy/create_apparatusinfo.php` // 权限分级列表
url_array['Authlist'] = `${url_}/admin/ljy/authlist.php` // 权限分级列表
url_array['Create_apparatusreturn'] = `${url_}/admin/ljy/create_apparatusreturn.php` // 创建归还
url_array['Get_apparatusinfo'] = `${url_}/admin/ljy/get_apparatusinfo.php` // 领用/外借/维修保养/保管变更记录
url_array['Edit_apparatus'] = `${url_}/admin/ljy/edit_apparatus.php` // 修改仪器
url_array['Create_apparatus'] = `${url_}/admin/ljy/create_apparatus.php` // 创建仪器
url_array['Manufacturerlist'] = `${url_}/admin/ljy/manufacturerlist.php` // 仪器分页列表
url_array['Get_apparatus'] = `${url_}/admin/ljy/get_apparatus.php` // 仪器分页列表
url_array['Edit_password'] = `${url_}/admin/danda/edit_password.php` // 修改密码
url_array['Delete_danda_qualify'] = `${url_}/admin/danda/delete_danda_qualify.php` // 删除证书
url_array['Save_danda_qualify'] = `${url_}/admin/danda/save_danda_qualify.php` // 创建个人能力库和证书
url_array['Create_danda_qualify'] = `${url_}/admin/danda/create_danda_qualify.php` // 创建个人能力库和证书
url_array['Bindsamplelist'] = `${url_}/admin/ljy/bindsamplelist.php` // 获取检测能力库分级列表
url_array['Bindsamplealllist'] = `${url_}/admin/ljy/bindsamplealllist.php` // 获取检测能力库列表
url_array['Get_danda_qualify'] = `${url_}/admin/danda/get_danda_qualify.php` // 获取个人能力和证书列表
url_array['Authalllist'] = `${url_}/admin/ljy/authalllist.php` // 获取权限所有列表
url_array['Create_sampletype'] = `${url_}/admin/ljy/create_sampletype.php` // 创建基础字段
url_array['Set_notice'] = `${url_}/admin/ljy/set_notice.php` // 设置我的通知状态
url_array['Myannouncement'] = `${url_}/admin/ljy/myannouncement.php` // 我的公告
url_array['Mynotice'] = `${url_}/admin/ljy/mynotice.php` // 我的通知/任务/进行中的任务/历史任务列表
url_array['Mylaboratory'] = `${url_}/admin/ljy/mylaboratory.php` // 我负责的实验室
url_array['Sampletypelist'] = `${url_}/admin/ljy/sampletypelist.php` // 获取基础字段全部列表
url_array['Del_qualify'] = `${url_}/admin/ljy/del_qualify.php` // 删除资质
url_array['Get_qualify_detail'] = `${url_}/admin/ljy/get_qualify_detail.php` // 根据Id获取资质详情
url_array['Edit_qualify'] = `${url_}/admin/ljy/edit_qualify.php` // 修改资质
url_array['Create_qualify'] = `${url_}/admin/ljy/create_qualify.php` // 创建资质
url_array['Qualifyalllist'] = `${url_}/admin/ljy/qualifyalllist.php` // 获取资质分级列表
url_array['Get_qualify'] = `${url_}/admin/ljy/get_qualify.php` // 获取资料列表
url_array['Get_templatecontent'] = `${url_}/admin/ljy/get_templatecontent.php` // 获取模板内容 委托单管理
url_array['Del_template'] = `${url_}/admin/ljy/del_template.php`; // 删除模板   委托单管理
url_array['Create_template'] = `${url_}/admin/ljy/create_template.php`; // 创建/修改委托单管理   委托单管理
url_array['Get_template_detail'] = `${url_}/admin/ljy/get_template_detail.php`; // 根据id获取委托单详情   委托单管理
url_array['Templatealllist'] = `${url_}/admin/ljy/templatealllist.php`; // 获取委托单分级列表   委托单管理
url_array['Get_form_word_detail'] = `${url_}/admin/task/get_form_word_detail.php`; // 根据id获取委托单字段详情   委托字段
url_array['Del_form_word'] = `${url_}/admin/task/del_form_word.php`; // 删除委托单字段   委托字段
url_array['Edit_form_word'] = `${url_}/admin/task/edit_form_word.php`; // 编辑委托单字段   委托字段
url_array['Create_form_word'] = `${url_}/admin/task/create_form_word.php`; // 创建委托单字段   委托字段
url_array['Formwordlist'] = `${url_}/admin/ljy/formwordlist.php`; // 获取委托单字段分级列表   委托字段
url_array['Authalllist'] = `${url_}/admin/ljy/authalllist.php`; //获取权限所有列表
url_array['Delete_danda'] = `${url_}/admin/danda/delete_danda.php`; //删除部门人员
url_array['Update_danda'] = `${url_}/admin/danda/update_danda.php`; //修改部门人员
url_array['Create_danda'] = `${url_}/admin/danda/create_danda.php`; //创建部门人员
url_array['Get_list'] = `${url_}/admin/danda/get_list.php`; //部门人员列表
url_array['Get_danda_detail'] = `${url_}/admin/danda/get_danda_detail.php`; //根据Id获取部门人员详情
url_array['Dandaalllist'] = `${url_}/admin/ljy/dandaalllist.php`; //获取部门人员所有分级列表
url_array['Get_samplelog'] = `${url_}/admin/ljy/get_samplelog.php`; //变更记录
url_array['Samplecategory'] = `${url_}/admin/ljy/samplecategory.php`; //样品类型文件夹列表
url_array['Get_samplecategory'] = `${url_}/admin/ljy/get_samplecategory.php`; //获取样品类型列表
url_array['Get_sampletype'] = `${url_}/admin/ljy/get_sampletype.php`; //获取基础字段列表
url_array['Del_sample'] = `${url_}/admin/ljy/del_sample.php`; //删除样品
url_array['Samplecategoryalllist'] = `${url_}/admin/ljy/samplecategoryalllist.php`; //获取样品类型所有分级列表
url_array['Edit_sample'] = `${url_}/admin/ljy/edit_sample.php`; // 样品管理 修改样品
url_array['Create_sample'] = `${url_}/admin/ljy/create_sample.php`; // 样品管理 创建样品
url_array['Get_sample'] = `${url_}/admin/ljy/get_sample.php`; // 样品管理 获取样品列表
url_array['Edit_samplecategory'] = `${url_}/admin/ljy/edit_samplecategory.php`; // 样品类型 修改样品类型
url_array['get_samplecategory_detail'] = `${url_}/admin/ljy/get_samplecategory_detail.php`; // 样品类型 根据id获取详情
url_array['Create_samplecategory'] = `${url_}/admin/ljy/create_samplecategory.php`; //创建样品类型
url_array['Samplecategoryalllist'] = `${url_}/admin/ljy/samplecategoryalllist.php`; //获取样品类型所有分级列表
url_array['del_sampletype'] = `${url_}/admin/ljy/del_sampletype.php`; // 删除样品类型
url_array['del_samplecategory'] = `${url_}/admin/ljy/del_samplecategory.php`; // 删除样品类型
url_array['Create_sampletype'] = `${url_}/admin/ljy/create_sampletype.php`; //基础字段
url_array['上传核酸检测excel'] = `${url_}/admin/yjs/nucleic_acid_upload.php`; //上传核酸excel
url_array['核酸检测自动完成数据'] = `${url_}/admin/yjs/nucleic_acid_auto_data.php`; //核酸检测自动完成数据
url_array['核酸检测项目保存'] = `${url_}/admin/yjs/nucleic_acid_save.php`; //核酸检测项目保存
url_array['核酸检测列表'] = `${url_}/admin/yjs/nucleic_acid_list.php`;
url_array['核酸检测详细列表'] = `${url_}/admin/yjs/nucleic_acid_detail.php`;
url_array['核酸检测详情'] = `${url_}/admin/yjs/nucleic_acid_info.php`;
url_array['核酸检测结果修改'] = `${url_}/admin/yjs/nucleic_acid_change_result.php`;
url_array['核酸检测结果审核'] = `${url_}/admin/yjs/nucleic_acid_audit.php`;
url_array['核酸检测打印所有报告'] = `${url_}/admin/yjs/nucleic_acid_print_all.php`;
url_array['核酸检测更改状态'] = `${url_}/admin/yjs/nucleic_acid_change_status.php`;
url_array['核酸检查询结果'] = `${url_}/admin/yjs/nucleic_acid_search.php`;
url_array['核酸检测导出'] = `${url_}/admin/yjs/nucleic_acid_export.php`;
url_array['核酸检测环境标本导出'] = `${url_}/admin/yjs/nucleic_acid_environment_export.php`;
url_array['保存核酸检测增加时间和结果时间'] = `${url_}/admin/yjs/nucleic_acid_change_time.php`;
export default url_array;
