import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
import Home from '../pages/home/home.vue'
import Page from '../components/page/page.vue'
import z from './z.js'

const routers = [{
    path: '/login',
    name: 'login',
    title: '登录',
    component: () => import('../pages/login/login.vue')
},
    {
        path: '/mathjax',
        name: 'mathjax',
        meta: {title: '公式'},
        component: () => import('../pages/demo/mathjax.vue'),
    },
    {
        path: '/daping/index',
        name: 'daping',
        meta: {title: '大屏'},
        component: () => import('../pages/daping/index.vue'),
    },
    {
        path: '/dapingstyle1/index',
        name: 'dapingstyle1',
        meta: {title: '大屏样式1'},
        component: () => import('../pages/daping/dapingstyle1/index.vue'),
    },
    {
        path: '/404',
        name: '404',
        component: () => import('../pages/404/404.vue'),
    },
    {
        path: '/',
        name: 'home',
        meta: {title: '首页'},
        component: Page,
        children: [{
            path: '/',
            name: 'home',
            meta: {title: '首页'},
            component: Home,
        }]
    }, {
        path: '/dev',
        name: 'dev',
        meta: {title: '开发工具'},
        component: Page,
        children: [{
            path: '/dev/postman',
            name: 'devPostman',
            meta: {title: 'Postman'},
            component: () => import('../pages/dev/postman/postman.vue'),
        }, {
            path: '/dev/upload',
            name: 'devUpload',
            meta: {title: '文件上传'},
            component: () => import('../pages/dev/upload/upload.vue'),
        }, {
            path: '/dev/callback',
            name: 'devCallback',
            meta: {title: '子传父回调'},
            component: () => import('../pages/dev/callback/callback.vue'),
        }, {
            path: '/dev/formula',
            name: 'formula',
            meta: {title: '公式'},
            component: () => import('../pages/dev/formula/formula.vue'),
        }, {
            path: '/dev/tree',
            name: 'tree',
            meta: {title: 'tree'},
            component: () => import('../pages/dev/tree/tree.vue'),
        }, {
            path: '/dev/table',
            name: 'table',
            meta: {title: 'table'},
            component: () => import('../pages/dev/table/table.vue'),
        }, {
            path: '/dev/dev',
            name: 'dev',
            meta: {title: 'dev'},
            component: () => import('../pages/dev/dev/dev.vue'),
        }]
    }, {
        path: '/example',
        name: 'example',
        meta: {title: '示例'},
        component: Page,
        children: [{
            path: '/example/example1',
            name: 'exampleExample1',
            meta: {title: '导航栏激活其他导航', active: 'devPostman'},
            component: () => import('../pages/example/example1/example1.vue'),
        }, {
            path: '/example/example2/:id',
            name: 'exampleExample2',
            meta: {title: '页面范围 & 页面参数', path: '/example/example2/0'},
            component: () => import('../pages/example/example2/example2.vue'),
        }, {
            path: '/example/example3',
            name: 'exampleExample3',
            meta: {title: '编辑抽屉'},
            component: () => import('../pages/example/example3/example3.vue'),
        }, {
            path: '/example/example4',
            name: 'exampleExample4',
            meta: {title: '编辑弹框'},
            component: () => import('../pages/example/example4/example4.vue'),
        }]
    }, {
        path: '/tpl',
        name: 'tpl',
        meta: {title: '模板'},
        component: Page,
        children: [{
            path: '/tpl',
            name: 'tpl',
            meta: {title: '模板'},
            component: () => import('../pages/tpl/tpl.vue'),
        }]
    }, {
        path: '/labKit',
        name: 'labKit',
        meta: {title: '实验室仪器管理'},
        component: Page,
        children: [{
            path: '/labKit/lab',
            name: 'labKitLab',
            meta: {title: '实验室管理'},
            component: () => import('../pages/labKit/lab/lab.vue'),
        }, {
            path: '/labKit/kit',
            name: 'labKitKit',
            meta: {title: '仪器管理'},
            component: () => import('../pages/labKit/kit/kit.vue'),
        },
            {
                path: '/labKit/shiyanhuanjing',
                name: 'shiyanhuanjing',
                meta: {title: '实验环境'},
                component: () => import('../pages/labKit/shiyanhuanjing/shiyanhuanjing.vue'),
            }]
    }, {
        path: '/jichuziduan',
        name: 'jichuziduan',
        meta: {title: '基础字段'},
        component: Page,
        children: [{
            path: '/jichuziduan/index',
            name: 'jichuziduan',
            meta: {title: '基础字段'},
            component: () => import('../pages/jichuziduan/index.vue'),
        }]
    },


    {
        path: '/renyuanguanli',
        name: 'renyuanguanli',
        meta: {title: '人员管理'},
        component: Page,
        children: [{
            path: '/renyuanguanli/index/:id',
            name: 'renyuanguanli',
            meta: {title: '人员管理'},
            component: () => import('../pages/renyuanguanli/renyuanguanli/index.vue'),
        },
            {
                path: '/department/department/:id',
                name: 'department',
                meta: {title: '人员管理1'},
                component: () => import('../pages/renyuanguanli/department/department.vue'),
            }, {
                path: '/erjirenyuan',
                name: 'erjirenyuan',
                meta: {title: '下级人员管理'},
                component: () => import('../pages/renyuanguanli/erjirenyuan/erjirenyuan.vue'),
            },
            {
                path: '/ziziguanli/index/:id',
                name: 'ziziguanli',
                meta: {title: '资质管理'},
                component: () => import('../pages/renyuanguanli/ziziguanli/index.vue'),
            },
            {
                path: '/auth/index/:id',
                name: 'auth',
                meta: {title: '权限列表'},
                component: () => import('../pages/renyuanguanli/auth/index.vue'),
            },
            {
                path: '/auth1/auth/:id',
                name: 'auth1',
                meta: {title: '权限列表1'},
                component: () => import('../pages/renyuanguanli/auth1/auth.vue'),
            }, {
                path: '/eidpassword/index',
                name: 'eidpassword',
                meta: {title: '修改密码'},
                component: () => import('../pages/renyuanguanli/eidpassword/index.vue'),
            }]
    },
    {
        path: '/shijiguanli',
        name: 'shijiguanli',
        meta: {title: '试剂管理'},
        component: Page,
        children: [{
            path: '/shijiguanli/index',
            name: 'shijiguanli',
            meta: {title: '试剂管理'},
            component: () => import('../pages/shijiguanli/shijiguanli/index.vue'),
        },
            {
                path: '/shijiruku/index',
                name: 'shijiruku',
                meta: {title: '试剂出入库记录'},
                component: () => import('../pages/shijiguanli/shijiruku/index.vue'),
            },
            {
                path: '/shijichangshang/index',
                name: 'shijichangshang',
                meta: {title: '生产厂商'},
                component: () => import('../pages/shijiguanli/shijichangshang/index.vue'),
            },
            {
                path: '/sjjingsiinfo/index',
                name: 'sjjingsiinfo',
                meta: {title: '试剂警示信息'},
                component: () => import('../pages/shijiguanli/sjjingsiinfo/index.vue'),
            },
            {
                path: '/jiancerenyuanauth/jiancerenyuanauth',
                name: 'jiancerenyuanauth',
                meta: {title: '检测人员权限'},
                component: () => import('../pages/jianceguanli/jiancerenyuanauth/jiancerenyuanauth.vue'),
            }
        ]
    },

    {
        path: '/jianceguanli',
        name: 'jianceguanli',
        meta: {title: '检测管理'},
        component: Page,
        children: [{
            path: '/jiancexiangmu/index/:id',
            name: 'jiancexiangmu',
            meta: {title: '检测项目'},
            component: () => import('../pages/jianceguanli/jiancexiangmu/index.vue'),
        }, {
            path: '/jiancebiaozhunwendang/index',
            name: 'jiancebiaozhunwendang',
            meta: {title: '检测标准文档'},
            component: () => import('../pages/jianceguanli/jiancebiaozhunwendang/index.vue'),
        }, {
            path: '/jiancefangfa/index/:id',
            name: 'jiancefangfa',
            meta: {title: '检测方法'},
            component: () => import('../pages/jianceguanli/jiancefangfa/index.vue'),
        }, {
            path: '/jiancenengli/index/:id',
            name: 'jiancenengli',
            meta: {title: '检测能力'},
            component: () => import('../pages/jianceguanli/jiancenengli/index.vue'),
        }, {
            path: '/taskability/taskability/:id',
            name: 'taskability',
            meta: {title: '检测能力1'},
            component: () => import('../pages/jianceguanli/taskability/taskability.vue'),
        }, {
            path: '/zhixingbiaozhun/zhixingbiaozhun/:id',
            name: 'zhixingbiaozhun',
            meta: {title: '执行标准'},
            component: () => import('../pages/jianceguanli/zhixingbiaozhun/zhixingbiaozhun.vue'),
        },
            {
                path: '/jiancebiaozhun/jiancebiaozhun/:id',
                name: 'jiancebiaozhun',
                meta: {title: '检测标准'},
                component: () => import('../pages/jianceguanli/jiancebiaozhun/jiancebiaozhun.vue'),
            },
            {
                path: '/jianceitem/jianceitem/:id',
                name: 'jianceitem',
                meta: {title: '检测项目'},
                component: () => import('../pages/jianceguanli/jianceitem/jianceitem'),
            },
            {
                path: '/jianceyinziguanli/jianceyinziguanli',
                name: 'jianceyinziguanli',
                meta: {title: '检测因子管理'},
                component: () => import('../pages/jianceguanli/jianceyinziguanli/jianceyinziguanli.vue'),
            },
            {
                path: '/jiancerenyuanauth/jiancerenyuanauth',
                name: 'jiancerenyuanauth',
                meta: {title: '检测人员权限'},
                component: () => import('../pages/jianceguanli/jiancerenyuanauth/jiancerenyuanauth.vue'),
            },
            {
                path: '/lihuaquxian/lihualine',
                name: 'lihualine',
                meta: {title: '理化曲线'},
                component: () => import('../pages/jianceguanli/lihuaquxian/lihualine.vue'),
            },
            {
                path: '/Checklist/Checklist/:id',
                name: 'Checklist',
                meta: {title: '原始记录单校验'},
                component: () => import('../pages/jianceguanli/Checklist/Checklist.vue'),
            },
            {
                path: '/shenhelist/shenhelist/:id',
                name: 'shenhelist',
                meta: {title: '原始记录单审核'},
                component: () => import('../pages/jianceguanli/shenhelist/shenhelist.vue'),
            },
            {
                path: '/baogaolist/baogaolist/:id',
                name: 'baogaolist',
                meta: {title: '报告校验'},
                component: () => import('../pages/jianceguanli/baogaolist/baogaolist.vue'),
            },
            {
                path: '/baogaolistshenhe/baogaolistshenhe/:id',
                name: 'baogaolistshenhe',
                meta: {title: '报告审核'},
                component: () => import('../pages/jianceguanli/baogaolistshenhe/baogaolistshenhe.vue'),
            },
            {
                path: '/resultinput/resultinput',
                name: 'resultinput',
                meta: {title: '结果录入'},
                component: () => import('../pages/jianceguanli/resultinput/resultinput.vue'),
            },
            {
                path: '/baogaobianzhi/baogaobianzhi',
                name: 'baogaobianzhi',
                meta: {title: '报告编制'},
                component: () => import('../pages/jianceguanli/baogaobianzhi/baogaobianzhi.vue'),
            },
            {
                path: '/baogaochaxun/baogaochaxun',
                name: 'baogaochaxun',
                meta: {title: '报告查询'},
                component: () => import('../pages/jianceguanli/baogaochaxun/baogaochaxun'),
            }

        ]
    },
    ...z,
    {
        path: '/shijizubieguanli',
        name: 'shijizubieguanli',
        meta: {title: '试剂组别管理'},
        component: Page,
        children: [{
            path: '/shijizubieguanli/index/',
            name: 'shijizubieguanli',
            meta: {title: '试剂组别管理'},
            component: () => import('../pages/shijizubieguanli/index.vue'),
        }]
    },
    {
        path: '/numguize',
        name: 'numguize',
        meta: {title: '试剂组别管理'},
        component: Page,
        children: [{
            path: '/numguize/numguize/',
            name: 'numguize',
            meta: {title: '编号规则'},
            component: () => import('../pages/numguize/numguize.vue'),
        }]
    },

    {
        path: '/gonggaoguanli',
        name: 'gonggaoguanli',
        meta: {title: '公告管理'},
        component: Page,
        children: [{
            path: '/gonggaoguanli/gonggao/',
            name: 'gonggao',
            meta: {title: '公告管理'},
            component: () => import('../pages/gonggaoguanli/gonggao.vue'),
        },
            {
                path: '/xiaoxitongzhi/xiaoxitongzhi',
                name: 'xiaoxitongzhi',
                meta: {title: '消息通知'},
                component: () => import('../pages/gonggaoguanli/xiaoxitongzhi/xiaoxitongzhi.vue'),
            },
            // {
            //     path: '/gonggaoguanli/gonggaonoauth',
            //     name: 'gonggaonoauth',
            //     meta: {title: '公告管理'},
            //     component: () => import('../pages/gonggaoguanli/gonggaonoauth/index.vue'),
            // }
        ]
    },

    {
        path: '/biaoqian',
        name: 'biaoqian',
        meta: {title: '标签'},
        component: Page,
        children: [{
            path: '/biaoqian/biaoqian/',
            name: 'biaoqian',
            meta: {title: '标签'},
            component: () => import('../pages/biaoqian/biaoqian.vue'),
        }]
    },
    {
        path: '/work',
        name: 'work',
        meta: {title: '工作量统计'},
        component: Page,
        children: [{
            path: '/work/work/',
            name: 'work',
            meta: {title: '工作量统计'},
            component: () => import('../pages/work/work.vue'),
        }]
    },

    {
        path: '/noauthgg',
        name: 'noauthgg',
        meta: {title: '公告1'},
        component: Page,
        children: [{
            path: '/noauthgg/noauthgg/',
            name: 'noauthgg',
            meta: {title: '公告1'},
            component: () => import('../pages/noauthgg/index.vue'),
        }]
    },
    {
        path: '/setxitong',
        name: 'setxitong',
        meta: {title: '系统设置'},
        component: Page,
        children: [{
            path: '/setxitong/setxitong/',
            name: 'setxitong',
            meta: {title: '系统设置'},
            component: () => import('../pages/setxitong/menu.vue'),
        },
            {
                path: '/setxitong/gongsi',
                name: 'gongsi',
                meta: {title: '导入公式'},
                component: () => import('../pages/setxitong/gongsi/gongsi.vue'),
            }, {
                path: '/setxitong/menuset',
                name: 'menuset',
                meta: {title: '菜单配置'},
                component: () => import('../pages/setxitong/menuset/menuset.vue'),
            },
            {
                path: '/setxitong/statistics',
                name: 'statistics',
                meta: {title: '数据统计'},
                component: () => import('../pages/setxitong/statistics/statistics.vue'),
            },
            {
                path: '/zizilist/zizilist',
                name: 'zizilist',
                meta: {title: '方法类别'},
                component: () => import('../pages/setxitong/zizilist/zizilist.vue'),
            },
            {
                path: '/fangfaliebie/fangfaliebie',
                name: 'fangfaliebie',
                meta: {title: '方法类别1'},
                component: () => import('../pages/setxitong/fangfaliebie/fangfaliebie.vue'),
            },

            {
                path: '/newmuban/newmuban',
                name: 'newmuban',
                meta: {title: '消息模板'},
                component: () => import('../pages/setxitong/newmuban/newmuban.vue'),
            },
            {
                path: '/mobanlist/mubanlist',
                name: 'mubanlist',
                meta: {title: '消息模板列表'},
                component: () => import('../pages/setxitong/mobanlist/mubanlist'),
            },
            {
                path: '/dapingmoban/dapingmuban',
                name: 'dapingmuban',
                meta: {title: '大屏模板'},
                component: () => import('../pages/setxitong/dapingmoban/dapingmuban'),
            },
            {
                path: '/dayin/dayin',
                name: 'dayin',
                meta: {title: '打印'},
                component: () => import('../pages/setxitong/dayin/dayin'),
            },
        ]
    },

    {
        path: '/hesuanjiance',
        name: 'hesuanjiance',
        meta: {title: '核酸检测'},
        component: Page,
        children: [{
            path: '/hesuanjiance/hesuanjiance/',
            name: 'hesuanjiance',
            meta: {title: '核酸检测'},
            component: () => import('../pages/hesuanjiance/hesuanjiance.vue'),
        },
            {
                path: '/hesuanjiance/jiancemoban',
                name: 'jiancemoban',
                meta: {title: '检测模板'},
                component: () => import('../pages/hesuanjiance/jiancemoban/jiancemoban.vue'),
            },

            {
                path: '/hesuanjiance/hesuanjeiguo',
                name: 'hesuanjeiguo',
                meta: {title: '核酸检测结果'},
                component: () => import('../pages/hesuanjiance/hesuanjeiguo/index.vue'),
            },
            {
                path: '/hesuanjiance/jieguo/:id',
                name: 'jieguo',
                meta: {title: '查看结果'},
                component: () => import('../pages/hesuanjiance/jieguo/jieguo.vue'),
            },
            {
                path: '/hesuanjiance/dayin',
                name: 'dayin',
                meta: {title: '核酸打印'},
                component: () => import('../pages/hesuanjiance/dayin/dayin.vue'),
            }]
    },
    {
        path: '/lulu',
        name: 'lulu',
        meta: {title: 'lulu'},
        component: Page,
        children: [{
            path: '/lulu/lulu/',
            name: 'lulu',
            meta: {title: 'lulu'},
            component: () => import('../pages/lulu/lulu.vue'),
        }]
    },

    {path: '*', name: 'noauth', component: () => import('../pages/noauth/noauth.vue')}]
const createRouter = () => new Router({
    mode: 'hash',
    routes: routers
})
const router = createRouter()
export default router
