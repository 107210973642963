const response = (e) => {
    const response = e.response
    const then = typeof e.then === 'undefined' ? (response) => console.log(response) : e.then
    const done = typeof e.done === 'undefined' ? (response) => console.log(response) : e.done
    const error = typeof e.error === 'undefined' ? (response) => console.log(response) : e.error
    done(response)
    if (response.code === 0) then(response)
    if (response.code !== 0) error(response)
}
export default response;
