import axios from 'axios'
import config from '../../config.js'
import member from '../../tool/member'
import store from '../../store/store.js'

const post = axios.create({
    timeout: config.postTimeout
})

post.interceptors.request.use(
    config => {
        store.state.loading = store.state.loading + 1
        config.headers['Authorization'] = 'Bearer ' + (member.getToken() ? member.getToken() : '')
        config.method = 'post'
        return config
    },
    error => {
        console.log('error')
        Promise.reject(error)
    }
)
post.interceptors.response.use(
    response => {
        store.state.loading = store.state.loading - 1
        console.log(response.status,'response.status')
        return (response.status === 200) ? response : ()=>{
            console.log('--------')
            Promise.reject('[ERROR] response.status: ' + response.status)
        }
    },
    error => {
        layer.msg('服务器异常')
        store.state.loading = store.state.loading - 1
        Promise.reject(error)
    }
)
export default post;
