import storage from '../sa0/lib/localStorage'

let token = {}
const tokenKey = 'MemberToken'
token.setToken = (token) => {
    storage.set(tokenKey, token);
}
token.getToken = () => {
    return storage.get(tokenKey);
}
token.delToken = () => {
    storage.del(tokenKey);
}

export default token;
