<template>
    <div class="home-container">

        <div class="gogngao-container">
            <div class="ggtitle"></div>
            <div class="ggtitletext">
                <h2>通知公告</h2>
                <span>announcement</span>
            </div>
            <div class="ggwraptext">
                <div v-for="( item,key) in tongzhitableData" :key="key" v-if="key===0">
                    {{item.content}}
                    <span class="fr">{{item.create_time}}</span></div>
            </div>
        </div>

        <div class="numbercontainer">
            <div class="numwrap">
                <div class="list">
                    <h2>累计受理委托单</h2>
                    <span @click="click1()" style="cursor: pointer"> <i class="numico iconfont icon-shouli vm" style="background: #dfeef5; color: #408dbb"></i> {{taskshouli_num}}</span>
                </div>
                <div class="list">
                    <h2>累计接收样品</h2>
                    <span @click="click2()" style="cursor: pointer"><i class="numico iconfont icon-jieshou1 vm" style="background: #fff3e3; color: #f6ae59; font-size: 26px"></i> {{yangpinjieshou_num}}</span>
                </div>
                <div class="list">
                    <h2>待采集任务</h2>
                    <span @click="click3()" style="cursor: pointer"><i class="numico iconfont icon-caiji vm" style="background: #daf0fb; color: #12a3e4"></i> {{daicaijirenwu_num}}</span>
                </div>
                <div class="list">
                    <h2>待检验项目</h2>
                    <span @click="click4()" style="cursor: pointer"><i class="numico iconfont icon-jianyan2 vm" style="background: #e1f9f9; color: #76d4d3"></i> {{daijianyanxiangmu_num}}</span>
                </div>
                <div class="list">
                    <h2>待校验项目</h2>
                    <span @click="click5()" style="cursor: pointer"><i class="numico iconfont icon-jianyan2 vm" style="background: #ffe4c3; color: #c8a36b"></i> {{daijiaoyan_num}}</span>
                </div>
            </div>


            <div class="numwrap">
                <div class="list">
                    <h2>待审核项目</h2>
                    <span @click="click6()" style="cursor: pointer"><i class="numico iconfont icon-shenhe vm" style="background: #fbe7e8; color: #f95657; font-size: 20px"></i> {{daishenhe_num}}</span>
                </div>
                <div class="list">
                    <h2>待编制报告</h2>
                    <span @click="click7()" style="cursor: pointer"><i class="numico iconfont icon-bianzhi vm" style="background: #dbf1ff; color: #3f9ac9; font-size: 20px"></i> {{daibianzhi_num}}</span>
                </div>
                <div class="list">
                    <h2>待校验报告</h2>
                    <span @click="click8()" style="cursor: pointer"><i class="numico iconfont icon-jianyan2 vm" style="background: #e2f5fb; color: #43cbd7; font-size: 20px"></i> {{bgdaijiaoyan_num}} </span>
                </div>
                <div class="list">
                    <h2>待审核报告</h2>
                    <span @click="click9()" style="cursor: pointer"><i class="numico iconfont icon-shenhe vm" style="background: #e1e6fa; color: #5472e5; font-size: 20px"></i> {{bgdaishenhe_num}}</span>
                </div>
                <div class="list">
                    <h2>待归档报告</h2>
                    <span @click="click10()" style="cursor: pointer"><i class="numico iconfont icon-guidang vm" style="background: #e1f9f9; color: #76d4d3; font-size: 20px"></i> {{bgdaiguidang_num}}</span>
                </div>
            </div>
        </div>
        <div class="numbercontain">
            <div class="numechart">
                <div class="numecharttitle">
                    近30天 受理委托单/检验项目/编制报告
                </div>
                <div>
                    <div id="weituonum" style="width: 800px; height: 580px; margin-top: 50px"></div>
                </div>
            </div>
            <div class="numechart">
                <div class="numecharttitle">
                    近30天 样品类型分布
                </div>
                <div>
                    <div id="samplenum" style="width: 800px; height: 750px; margin-top: 50px"></div>
                </div>
            </div>
        </div>

        <!--        <div class="weituotable">-->
        <!--            <a-tabs @change="callback" v-model="tab_active">-->
        <!--                <a-tab-pane key="1" tab="委托超期"></a-tab-pane>-->
        <!--                <a-tab-pane key="2" tab="试验超期"></a-tab-pane>-->
        <!--                <a-tab-pane key="3" tab="留样超期"></a-tab-pane>-->
        <!--            </a-tabs>-->
        <!--            <div v-if="tab_active === '1'">-->
        <!--                <table class="layui-table">-->
        <!--                    <thead>-->
        <!--                    <tr>-->
        <!--                        <th>序号</th>-->
        <!--                        <th>委托单号</th>-->
        <!--                        <th>委托单位</th>-->
        <!--                        <th>样品编号</th>-->
        <!--                        <th>样品名称</th>-->
        <!--                        <th>委托日期</th>-->
        <!--                        <th>受理日期</th>-->
        <!--                        <th>报告交付日期</th>-->
        <!--                        <th>委托状态</th>-->
        <!--                    </tr>-->
        <!--                    </thead>-->
        <!--                    <tbody>-->
        <!--                    <tr>-->
        <!--                        <td>1</td>-->
        <!--                        <td>236547896544</td>-->
        <!--                        <td>秦皇岛大胆网络公司</td>-->
        <!--                        <td>2365412255</td>-->
        <!--                        <td>地质水</td>-->
        <!--                        <td>2022-5-5</td>-->
        <!--                        <td>2022-5-6</td>-->
        <!--                        <td>2022-5-6</td>-->
        <!--                        <td>报告编审中</td>-->
        <!--                    </tr>-->
        <!--                    <tr>-->
        <!--                        <td>1</td>-->
        <!--                        <td>236547896544</td>-->
        <!--                        <td>秦皇岛大胆网络公司</td>-->
        <!--                        <td>2365412255</td>-->
        <!--                        <td>地质水</td>-->
        <!--                        <td>2022-5-5</td>-->
        <!--                        <td>2022-5-6</td>-->
        <!--                        <td>2022-5-6</td>-->
        <!--                        <td>报告编审中</td>-->
        <!--                    </tr>-->
        <!--                    <tr>-->
        <!--                        <td>1</td>-->
        <!--                        <td>236547896544</td>-->
        <!--                        <td>秦皇岛大胆网络公司</td>-->
        <!--                        <td>2365412255</td>-->
        <!--                        <td>地质水</td>-->
        <!--                        <td>2022-5-5</td>-->
        <!--                        <td>2022-5-6</td>-->
        <!--                        <td>2022-5-6</td>-->
        <!--                        <td>报告编审中</td>-->
        <!--                    </tr>-->
        <!--                    <tr>-->
        <!--                        <td>1</td>-->
        <!--                        <td>236547896544</td>-->
        <!--                        <td>秦皇岛大胆网络公司</td>-->
        <!--                        <td>2365412255</td>-->
        <!--                        <td>地质水</td>-->
        <!--                        <td>2022-5-5</td>-->
        <!--                        <td>2022-5-6</td>-->
        <!--                        <td>2022-5-6</td>-->
        <!--                        <td>报告编审中</td>-->
        <!--                    </tr>-->
        <!--                    </tbody>-->
        <!--                </table>-->

        <!--            </div>-->
        <!--            <div v-if="tab_active === '2'">222</div>-->
        <!--            <div v-if="tab_active === '3'">333</div>-->

        <!--        </div>-->


    </div>
</template>

<script>
    export default {
        name: "home2",
        data() {
            return {
                tab_active: "1",
                tongzhitableData: [],
                taskshouli_num: '',
                yangpinjieshou_num: '',
                daicaijirenwu_num: '',
                daijianyanxiangmu_num: '',
                daijiaoyan_num: '',
                daishenhe_num: '',
                daibianzhi_num: '',
                bgdaishenhe_num: '',
                bgdaiguidang_num: '',
                bgdaijiaoyan_num: ''
            };
        },
        mounted() {
            this.Myannouncement() //我的通知
            this.Get_info_num() //首页个数
            this.weituonum()
            this.baogaonum()
        },
        methods: {
            click1() {
                this.$router.push('/weituodanguanlinew/weituodanguanlinew')
            },
            click2() {
                this.$router.push('/samplelist/samplilist/0?id=170&page=1&status=0&type=&name=')
            },
            click3() {
                this.$router.push('/sampleguanli/caijiauth/0?status=1&keywords=&spec_types=&page=1')
            },
            click4() {
                this.$router.push('/resultinput/resultinput')
            },
            click5() {
                this.$router.push('/Checklist/Checklist/0?inter_code=&item_name=&method_name=&state=2&starttime=&endtime=')
            },
            click6() {
                this.$router.push('/shenhelist/shenhelist/0?inter_code=&item_name=&method_name=&state=3')
            },
            click7() {
                this.$router.push('/baogaobianzhi/baogaobianzhi')
            },
            click8() {
                this.$router.push('/baogaolist/baogaolist/0?task_num=&state=8')
            },
            click9() {
                this.$router.push('/baogaolistshenhe/baogaolistshenhe/0?task_num=&state=3')
            },
            click10() {
                this.$router.push('/weituodanguanlinew/weituodanguanlinew')
            },


            // 我的通知
            Myannouncement() {
                this.$sa0.post({
                    url: this.$api('Myannouncement'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.tongzhitableData = response.data.result.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 个数
            Get_info_num() {
                this.$sa0.post({
                    url: this.$api('Get_info_num'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.taskshouli_num = response.data.result.taskshouli_num;
                            this.daicaijirenwu_num = response.data.result.daicaijirenwu_num;
                            this.daijianyanxiangmu_num = response.data.result.daijianyanxiangmu_num;
                            this.daijiaoyan_num = response.data.result.daijiaoyan_num;
                            this.daishenhe_num = response.data.result.daishenhe_num;
                            this.daibianzhi_num = response.data.result.daibianzhi_num;
                            this.bgdaishenhe_num = response.data.result.bgdaishenhe_num;
                            this.bgdaiguidang_num = response.data.result.bgdaiguidang_num;
                            this.yangpinjieshou_num = response.data.result.yangpinjieshou_num;
                            this.bgdaijiaoyan_num = response.data.result.bgdaijiaoyan_num;
                            console.log(123, this.taskshouli_num)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            weituonum() {
                this.$sa0.post({
                    url: this.$api('Home_left'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            var chartDom = document.getElementById('weituonum');
                            var myChart = echarts.init(chartDom);
                            var option;
                            let data = response.data;
                            console.log(response.data)
                            option = {
                                title: {},
                                tooltip: {
                                    trigger: 'axis',
                                    axisPointer: {
                                        type: 'cross',
                                        label: {
                                            backgroundColor: '#6a7985'
                                        }
                                    }
                                },
                                legend: {
                                    data: ['受理委托单', '检验项目', '编制报告']
                                },
                                toolbox: {
                                    feature: {}
                                },
                                grid: {
                                    left: '3%',
                                    right: '4%',
                                    bottom: '3%',
                                    containLabel: true
                                },
                                xAxis: [
                                    {
                                        type: 'category',
                                        boundaryGap: false,

                                        data: data.data_1[0],
                                    }
                                ],
                                yAxis: [
                                    {
                                        type: 'value'
                                    }
                                ],
                                series: [
                                    {
                                        name: '受理委托单',
                                        type: 'line',
                                        stack: 'Total',
                                        areaStyle: {},
                                        emphasis: {
                                            focus: 'series'
                                        },
                                        data: data.data_1[1],
                                    },
                                    {
                                        name: '检验项目',
                                        type: 'line',
                                        stack: 'Total',
                                        areaStyle: {},
                                        emphasis: {
                                            focus: 'series'
                                        },
                                        data: data.data_2[1],
                                    },
                                    {
                                        name: '编制报告',
                                        type: 'line',
                                        stack: 'Total',
                                        areaStyle: {},
                                        emphasis: {
                                            focus: 'series'
                                        },
                                        data: data.data_3[1],
                                    },
                                ]
                            };
                            option && myChart.setOption(option);
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })

            },

            baogaonum() {
                this.$sa0.post({
                    url: this.$api('Home_right'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            var chartDom = document.getElementById('samplenum');
                            var myChart = echarts.init(chartDom);
                            var option;
                            let data = response.data;
                            option = {
                                title: {
                                    text: '',
                                    left: 'center'
                                },
                                tooltip: {
                                    trigger: 'item'
                                },
                                legend: {
                                    orient: 'horizontal',
                                    left: 'center'
                                },
                                series: [
                                    {
                                        name: 'Access From',
                                        type: 'pie',
                                        radius: '50%',
                                        data: data.data,
                                        emphasis: {
                                            itemStyle: {
                                                shadowBlur: 10,
                                                shadowOffsetX: 0,
                                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                                            }
                                        }
                                    }
                                ]
                            };

                            option && myChart.setOption(option);


                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            callback(key) {
                console.log(key);
            },
        },
    }
</script>

<style scoped>

    .fr{ float: right}

    .home-container{ height: 100%; background: #f9f9f9; padding: 20px; margin-left: -20px; margin-right: -20px }

    .user-name{ font-size: 20px; font-weight: bold; color: #384a56 }

    .user-name span{ font-size: 28px}

    .gogngao-container{ width: 100%; padding: 0; background: #ffffff; margin-top: 20px; border: 1px solid #eeeeee; border-radius: 4px; height: 80px; position: relative}

    .ggtitle{width: 180px;border-top: 78px solid #1890ff;border-right: 50px solid transparent; height: 100px; position: absolute; left: 0; z-index: 0}

    .ggtitletext{ position: absolute; z-index: 99; width: 200px; }

    .ggtitletext h2{ font-size: 20px; color: #ffffff; padding-left: 20px; padding-top: 10px; margin: 0; font-weight: bold}

    .ggtitletext span{ font-size: 14px; color: #ffffff; padding-left: 20px; display: inline-block; opacity: 0.6}

    .ggwraptext{ margin-left: 200px; font-size: 16px; line-height: 80px; padding-right: 20px; color: #333}

    .numbercontainer{ width: 100%; padding: 20px; box-sizing: border-box; border: 1px solid #eeeeee; margin-top: 20px; background: #ffffff;}

    .numwrap{ width: 100%; display: flex; justify-content: space-around; text-align: center; flex-wrap: wrap }

    .numwrap .list{ border-right: 1px solid #f3f3f3; width: 20%;}

    .numwrap .list:last-child{ border: none}

    .numwrap{ border-bottom: 1px solid #f3f3f3; padding: 20px 0}

    .numwrap:last-child{ border: none}

    .numwrap .list h2{ font-size: 18px; font-weight: bold; color: #31617d; margin-bottom: 30px}

    .numwrap .list span{ font-size: 20px; vertical-align: center; display: inline-block; }

    .numwrap .list span .numico{ width: 30px; height: 30px; display: inline-block; background: #d5f3fb; border-radius: 100%;vertical-align: top; text-align: center; line-height: 30px }

    .numbercontain{ width: 100%; padding: 20px; background: #ffffff; border: 1px solid #eeeeee; margin-top: 20px}

    .numbercontain{ width: 100%; display: flex; justify-content: space-between; height: 750px; overflow-y: hidden}

    .numbercontain .numechart{ width: 50%}

    .numbercontain .numechart .numecharttitle{ font-size: 18px; font-weight: bold }

    .weituotable{ width: 100%; padding: 20px; background: #ffffff; border: 1px solid #eeeeee; margin-top: 20px }

    .vm{ vertical-align: middle}

</style>
