import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        loading: 0,
        history_item: false,
        nav_current: [],
        tab: [],
        tab_active: '',
        breadcrumb: []
    }
})
export default store
