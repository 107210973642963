// main.js
import Vue from "vue";
import App from "./App.vue"
import router from './router/router'
import store from './store/store'
import './permission';

import './assets/strawberry-v2.0.0/style.css'
import 'tailwindcss/dist/tailwind.min.css'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './assets/font/iconfont.css'

Vue.config.productionTip = false;
Vue.use(Antd);

import config from './config.js'


Vue.prototype.$config = config;

import sa0 from './sa0/sa0'
import api from './api/api'
import member from './tool/member'


import Router from 'vue-router'

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}


Vue.prototype.$api = api;
Vue.prototype.$sa0 = sa0;
Vue.prototype.$member = member;

new Vue({
    el: "#app",
    store,
    router,
    render: (h) => h(App)
}).$mount();
