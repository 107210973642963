<script>
import storage from "../../sa0/lib/localStorage";

export default {
  data() {
    return {
      panes: this.$store.state.tab,
      newTabIndex: 0,
    };
  },
  computed: {
    tab_active() {
      return this.$store.state.tab_active
    },
  },
  mounted() {
  },
  methods: {
    tabRemove(name) {
      let tab_list = this.$store.state.tab
      let remove_index = -1
      for (let i in tab_list) {
        if (name === tab_list[i].key) {
          remove_index = i
          break
        }
      }
      if (remove_index !== -1) {
        tab_list.splice(remove_index, 1)
        this.$store.state.tab = tab_list
        let user_account = storage.get('USER_account');
        user_account = user_account ? user_account : '';
        const tabListKey = 'TAB_LIST' + user_account
        this.$sa0.localStorage.set(tabListKey, tab_list)
        if (this.tab_active === name) this.$router.push('/');
      }
    },
    tabItemClick(name) {
      if (this.tab_active === name) return;
      let tab_list = this.$store.state.tab
      for (let i in tab_list) {
        if (name === tab_list[i].key) {
          this.$router.push(tab_list[i].path)
          break
        }
      }
    }
  },
};
</script>
<template>
  <div class="tab_wrapper">
    <a-tabs :activeKey="tab_active" @edit="tabRemove" :hide-add="true" @tabClick="tabItemClick" type="editable-card">
      <a-tab-pane v-for="pane in panes"
                  :tab="pane.title"
                  :key="pane.key"
                  :closable="pane.key !== 'home'">
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<style scoped>
.tab_wrapper {
  padding: 10px 10px 0 10px;
}
</style>
