import html2canvas from "html2canvas";

const h2c = (el, then) => {
    let main = document.getElementById(el);
    html2canvas(main, {
        dpi: window.devicePixelRatio * 2,
        width: parseInt(main.offsetWidth) + 10,
        height: parseInt(main.offsetHeight) + 10,
        scale: 2,
        useCORS: true,
    }).then((canvas) => {
        let dataUrl = canvas.toDataURL("image/png", 1.0);
        then(dataUrl)
    });
}
export default h2c;
