let ls = {};
ls.set = (key, val) => {
    localStorage.setItem(key, JSON.stringify(val));
};

ls.get = (key) => {
    let val = localStorage.getItem(key);
    return (val !== 'undefined' && val)
        ? JSON.parse(localStorage.getItem(key))
        : false;
};
ls.del = (key) => {
    let val = localStorage.getItem(key);
    if (val !== 'undefined' && val) localStorage.removeItem(key);
};


export default ls;