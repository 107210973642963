<script>
export default {
  computed: {
    breadcrumb() {
      return this.$store.state.breadcrumb
    },
  },
  methods: {
    itemClick(key) {
      if (key === 0) {
        this.$router.push('/')
      }
    }
  },
}
</script>
<template>
  <div>
    <a-breadcrumb class="breadcrumb_wrapper">
      <a-breadcrumb-item v-for="(item, key) in breadcrumb" :key="key">
        <span class="bread_item_wrapper" @click="itemClick(key)">{{ item.title }}</span>
      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>
<style scoped>
.breadcrumb_wrapper {
  padding-left: 20px;
  line-height: 50px;
  height: 50px;
  background: #ffffff;
}

.bread_item_wrapper {
  cursor: pointer;
}
</style>
