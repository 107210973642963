import Page from "../components/page/page";

let arr = [
    {
        path: '/demo',
        name: 'demo',
        meta: {title: 'Demo'},
        component: Page,
        children: [{
            path: '/demo/auto_input',
            name: 'AutoInput',
            meta: {title: '自动填写'},
            component: () => import('../pages/demo/auto_input/auto_input.vue'),
        }]
    },
    {
        path: '/weituotaskguanli',
        name: 'weituotaskguanli',
        meta: {title: '委托任务管理'},
        component: Page,
        children: [
            {
                path: '/weituoziduanguanli/index/:id',
                name: 'weituoziduanguanli',
                meta: {title: '委托字段管理'},
                component: () => import('../pages/weituotaskguanli/weituoziduanguanli/index.vue'),
            },
            {
                path: '/weituoziduan/weituoziduan/:id',
                name: 'weituoziduan',
                meta: {title: '委托字段管理'},
                component: () => import('../pages/weituotaskguanli/weituoziduan/weituoziduan'),
            },
            {
                path: '/weituodanguanli1/weituodanguanli1/:id',
                name: 'weituodanguanli1',
                meta: {title: '委托单模板管理'},
                component: () => import('../pages/weituotaskguanli/weituodanguanli1/weituodanguanli1'),
            },
            {
                path: '/weituodanguanli/index/:id',
                name: 'weituodanguanli',
                meta: {title: '委托单管理'},
                component: () => import('../pages/weituotaskguanli/weituodanguanli/index.vue'),
            },
            {
                path: '/createtaskdan/index/:id',
                name: 'createtaskdan',
                meta: {title: '创建任务委托单'},
                component: () => import('../pages/weituotaskguanli/createtaskdan/index.vue'),
            },
            {
                path: '/weituodanguanli/before/:id',
                name: 'weituodanguanli_before',
                meta: {title: '预委托单管理'},
                component: () => import('../pages/weituotaskguanli/before/before.vue'),
            },
            // {
            //     path: '/addweituodan/addweituodan/:id',
            //     name: 'addweituodan',
            //     meta: {title: '添加委托单'},
            //     component: () => import('../pages/weituotaskguanli/addweituodan/addweituodan.vue'),
            // },
            {
                path: '/addweituodan/addweituodan/:id',
                name: 'addweituodan',
                meta: {title: '任务委托单管理'},
                component: () => import('../pages/weituotaskguanli/task_list/task_list.vue'),
            },

            {
                path: '/addcheck/addcheck/:id',
                name: 'addcheck',
                meta: {title: '样品检测管理'},
                component: () => import('../pages/weituotaskguanli/task_check_list/task_list.vue'),
            },

            {
                path: '/weituodanguanlinew/weituodanguanlinew',
                name: 'weituodanguanlinew',
                meta: {title: '委托单管理'},
                component: () => import('../pages/weituotaskguanli/weituodanguanlinew/weituodanguanlinew.vue'),
            },
            {
                path: '/tasklist2',
                name: 'tasklist2',
                meta: {title: '委托任务'},
                component: () => import('../pages/weituotaskguanli/tasklist2/tasklist2.vue'),
            },
            {
                path: '/erjiyangpin',
                name: 'erjiyangpin',
                meta: {title: '样品接收'},
                component: () => import('../pages/weituotaskguanli/erjiyangpin/erjiyangpin.vue'),
            },
            {
                path: '/erjiweituo',
                name: 'erjiweituo',
                meta: {title: '派发委托任务'},
                component: () => import('../pages/weituotaskguanli/erjiweituo/erjiweituo.vue'),
            },

            {
                path: '/yangpinjianceguanli/index',
                name: 'yangpinjianceguanli',
                meta: {title: '样品检测管理'},
                component: () => import('../pages/weituotaskguanli/yangpinjianceguanli/index.vue'),
            },

            {
                path: '/startCheck/:id',
                name: 'startCheck',
                meta: {title: '开始检测'},
                component: () => import('../pages/weituotaskguanli/startCheck/startCheck.vue'),
            },
            {
                path: '/jcbg/tpl/:id',
                name: 'jcbgtpl',
                meta: {title: '报告模板'},
                component: () => import('../pages/jianceguanli/jieguobaogaotpl/jieguobaogaotpl.vue'),
            },
            {
                path: '/jcbg/table/:id',
                name: 'jcbgtable',
                meta: {title: '检测报告'},
                component: () => import('../pages/jianceguanli/jieguobaogao/jieguobaogao.vue'),
            },
            {
                path: '/originalRecord/:id',
                name: 'originalRecord',
                meta: {title: '原始记录单模板'},
                component: () => import('../pages/weituotaskguanli/originalRecord/originalRecord.vue'),
            },
        ]
    },
    {
        path: '/sampleguanli',
        name: 'sampleguanli',
        meta: {title: '样品管理'},
        component: Page,
        children: [
            {
                path: '/sampletype/index/:id',
                name: 'sampletype',
                meta: {title: '样品类型'},
                component: () => import('../pages/sampleguanli/sampletype/index.vue'),
            },

            {
                path: '/sampletype1/sampletype/:id',
                name: 'sampletype1',
                meta: {title: '样品类型'},
                component: () => import('../pages/sampleguanli/sampletype1/sampletype.vue'),
            },

            {
                path: '/sampletype2/sampletype/:id',
                name: 'sampletype2',
                meta: {title: '样品类型'},
                component: () => import('../pages/sampleguanli/sampletype2/sampletype.vue'),
            },
            {
                path: '/sampleguanli/index',
                name: 'sampleguanli',
                meta: {title: '样品管理'},
                component: () => import('../pages/sampleguanli/sampleguanli/index.vue'),
            },
            {
                path: '/caijijieshouyangpinguanli/index',
                name: 'caijijieshouyangpinguanli',
                meta: {title: '采集接收样品管理'},
                component: () => import('../pages/sampleguanli/caijijieshouyangpinguanli/index.vue'),
            },
            {
                path: '/sampleguanli/group/:id',
                name: 'sampleguanli_group',
                meta: {title: '样品组管理'},
                component: () => import('../pages/sampleguanli/group/group.vue'),
            },
            {
                path: '/sampleguanli/create/:id',
                name: 'yp_create',
                meta: {title: '新增样品'},
                component: () => import('../pages/sampleguanli/create/create.vue'),
            },
            {
                path: '/sampleguanli/caijiguanli/:id',
                name: 'caijiguanli',
                meta: {title: '采集管理'},
                component: () => import('../pages/sampleguanli/caijiguanli/caijiguanli.vue'),
            },
            {
                path: '/sampleguanli/jieshouguanli/:id',
                name: 'jieshouguanli',
                meta: {title: '接收管理'},
                component: () => import('../pages/sampleguanli/jieshouguanli/jieshouguanli.vue'),
            },
            {
                path: '/sampleguanli/caijiauth/:id',
                name: 'caijiauth',
                meta: {title: '采集权限'},
                component: () => import('../pages/sampleguanli/caijiauth/caijiauth.vue'),
            },
            {
                path: '/tasklist',
                name: 'tasklist',
                meta: {title: '委托采集任务'},
                component: () => import('../pages/sampleguanli/tasklist/tasklist.vue'),
            },
            {
                path: '/sampleguanli/jieshouauth/:id',
                name: 'jieshouauth',
                meta: {title: '接收权限'},
                component: () => import('../pages/sampleguanli/jieshouauth/jieshouauth.vue'),
            },
            {
                path: '/sampleguanli/caijijieshou',
                name: 'caijijieshou',
                meta: {title: '采集接收'},
                component: () => import('../pages/sampleguanli/caijijieshou/caijijieshou.vue'),
            },
            {
                path: '/samplelist/samplilist/:id',
                name: 'samplelist',
                meta: {title: '样品管理'},
                component: () => import('../pages/sampleguanli/samplelist/samplilist'),
            },
            {
                path: '/samplemuban/samplemuban/:id',
                name: 'samplemuban',
                meta: {title: '样品流传单模板'},
                component: () => import('../pages/sampleguanli/samplemuban/samplemuban'),
            },
            {
                path: '/caiyangmuban/caiyangmuban/:id',
                name: 'caiyangmuban',
                meta: {title: '采样单模板'},
                component: () => import('../pages/sampleguanli/caiyangmuban/caiyangmuban'),
            },
            {
                path: '/baoyanmuban/baoyanmuban/:id',
                name: 'baoyanmuban',
                meta: {title: '报验单模板'},
                component: () => import('../pages/sampleguanli/baoyanmuban/baoyanmuban'),
            },

        ]
    },]
export default arr;
