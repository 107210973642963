<script>
export default {
  data() {
    return {
      routers: [],
      r: [{
        path: '/',
        name: 'home',
        title: '首页',
        children: [{
          path: '/',
          title: '首页',
          name: 'home',
        }]
      }
      // , {
      //   path: '/dev',
      //   name: 'dev',
      //   title: '开发工具',
      //   children: [{
      //     path: '/dev/postman',
      //     name: 'devPostman',
      //     title: 'Postman',
      //   }, {
      //     path: '/dev/upload',
      //     name: 'devUpload',
      //     title: '文件上传',
      //   }, {
      //     path: '/dev/tree',
      //     name: 'Tree',
      //     title: '树组件',
      //   }, {
      //     path: '/dev/formula',
      //     name: 'formula',
      //     title: '公式',
      //   }]
      // }
      ]
    }
  },
  computed: {
    current() {
      return this.$store.state.nav_current
    },
  },
  mounted() {
    this.getNav()
  },
  methods: {
    getNav() {
      this.$sa0.post({
        url: this.$api('导航栏'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.routers = [
              ...this.r,
              ...response.data.auth
            ];
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    menuItemClick(item) {
      let blank_jump_arr = ['daping'];
      if (blank_jump_arr.indexOf(item.name) != -1) {
        const {href} = this.$router.resolve({
          name: item.name
        })
        window.open(href, '_blank')
      } else {
        this.$router.push(item.path)
      }
    }
  }
}
</script>
<template>
  <div>
    <a-menu class="menu_wrapper" :selectedKeys="current" mode="horizontal" theme="dark">
      <template v-for="(item, key) in routers">
        <a-menu-item @click="menuItemClick(item.children[0])" v-if="item.children.length === 1"
                     :key="item.children[0].name">
          {{ item.children[0].title }}
        </a-menu-item>
        <a-sub-menu v-else>
          <span slot="title">{{ item.title }}</span>
          <a-menu-item @click="menuItemClick(i)" v-for="(i, k) in item.children" :key="i.name">
            {{ i.title }}
          </a-menu-item>
        </a-sub-menu>
      </template>
    </a-menu>
  </div>
</template>
<style scoped>
.menu_wrapper {
  background: none;
  height: 60px;
  line-height: 60px;
}
</style>
